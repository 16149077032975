.container-pergunta{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.content-perguntas {    
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;   
    align-items: center;

   
}

