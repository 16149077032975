.container-pergunta{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.content-perguntas {    
    width: 95%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;   
    align-items: center;
  
}

#container-faq{
    margin-top: 0rem;
    margin-bottom: 3rem;
    width: 100%;  
}


/* 
#background-faq{
    margin-left: 22rem;
    border-radius: 1rem;
    width: 60%;
    padding: 2rem 0; 
    background-color: #6e6363;
}

 */

@media( min-width:  766px){
    #container-faq .tituloPagina{
        margin-left: 6%;
    }
}