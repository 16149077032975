.container-municipio{
    display: flex;
    justify-content: center;
    gap: 5rem;
    margin-top: 3rem;
    flex-wrap: wrap;
    color: var(--mainColor);
}

   /*  titulo  */ 
.container-municipio h2{        
    font-weight: lighter;
}

.municipio{
    display: flex;
    flex-direction: column;
}
.menu-municipio{
    width: 20%;
    height: 40%;
    margin-bottom: 4%;
}


.menu-municipio img{
    width: 100%;
    padding: 15px 15px;
    border-radius: 20px;
}

.container-prefeita{  
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    padding: 10px 10px;
    border-radius: 10px;
}

.menuItem{
    margin-top: 1rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    padding: 10px 10px;
    border-radius: 10px;
}


.menuItemInfo{
    margin-top: 1rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    padding: 10px 10px;
    border-radius: 10px;
   
}
.menuItemInfo li{
    font-family: var(--fontA);
    font-size: 20px;
    font-weight: 400;
}

.menuItem li:hover{
    
    cursor: pointer;
    color: var(--mainColor)
}

.bg-text h2,h3{
    text-align: center;
    font-size: 24px;
    font-family: var(--fontA);
}
.container{
    max-width: 1036px;
    min-width: 100px;
}
.container img{
    width: 1036px;
    margin-top: 2rem;
    border-radius: 36px;
}
.container p{
    font-family: var(--fontA);
    text-align: justify;
}
.municipioSobre{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;       
}

.municipioSobre p{
    font-family: var(--fontA);
    font-size: 20px;
    font-weight: 400;
    line-height: 2rem;
}

.historia {
    margin-top: 2rem;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}

.historia p{
    font-family: var(--fontA);
    font-size: 20px;
    font-weight: 400;
    line-height: 2rem;
    margin-bottom: 2rem;
}
.hino{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; 
    gap: 2rem;
}
.hino p{
    font-family: var(--fontA);
    font-size: 20px;
    font-weight: 400;
    line-height: 2rem;
    margin-bottom: 2rem;
}

.text-hino{
    display: flex;
    gap: 3rem;
}

.simbolos ::-webkit-scrollbar-thumb{
    padding: 2rem 0;
    width: 100%;
}


.simbolo-img img{
    width: 3rem;
    height: 15rem;
}

.cardImg{
    width: 1100px;
    display: flex;
    gap: 5rem;
    align-items: center;
    overflow-x: auto;
}


.cardImg img{
    width: auto;
    height: 13rem;
   
}
 /* Pontos tiristicos */
.pontosTuristicos{
    margin: 4rem 0;
    display: flex;  
    flex-direction: column;
    align-items: center;
   gap: 2rem;
}
.mapouter{
    display: flex;
    justify-content: space-between;
    gap: 10rem;
}

.mapouter h2{
    margin-top: 3rem;
    width: 17rem;
    font-family: var(--fontA);
    font-size: 28px;
}
.mapouter button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: none;
    background-color: none;

    
    background-color: var(--textColor);
    width: 16rem;
    padding: 10px;
    border-radius: 20px;
    outline: none;
    cursor: pointer;
    font-size: 20px;
    color: var(--textColor2);
    margin-top: 1rem;
}

.mapouter span{
    font-family: var(--fontA);
    font-weight: 400;
    color: var(--textColor2);
    font-size: 20px;
    text-transform: uppercase;
}


.gmap_canvas iframe{
    height: 20rem;
    width: 20rem;
    border-radius: 0%;
    border: 1px solid black;
}


.historia .carrousel img{
    border-radius: 10px;
}

@media (max-width: 361px) {

    .container-municipio{
        overflow-x: hidden;
     }

     .mapouter a{
        position: relative;
        display: flex;
        left: -12px;
     }  

     .mapouter h2{
        position: relative;
        display: flex;
        font-size: 22px;
        left: -10px;
     }   
     
}  


@media (max-width: 376px) {

    .container-municipio{
        margin-left: -0.6rem;
        margin-right: 0.5rem;
     }

     .mapouter a{
        position: relative;
        display: flex;
        margin-left: -2rem;
     }  

     .mapouter h2{
        margin-left: 1rem;
     }   
     
}  


@media (max-width: 500px) {

    .menu-municipio{
        max-width: 24rem;
     }

     .mapouter a{
        position: relative;
        display: flex;
        margin-left: -1rem;
     }     
}  

@media (width < 842px){
    .municipio{
        display: flex;
        width: 100%;
        margin-top: -5%;
        
        padding-left: 15px;    
        padding-right: 15px;
     }
     .municipioSobre .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;                 
     }
     .municipioSobre .container p{
        font-size: 20px;   
        width: 100%;
     }
     .municipioSobre .container img{ 
        width: 100%;        
     }
 
     .historia .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
     }
     .historia .container p{
         font-size: 20px;
         width: 100%;        
         
      }
      .historia .carrousel{
        width: 100%;          
      }
      
      .hino .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
      }
 
      .text-hino {
         flex-direction: column;
         font-size: 20px;
         width: 100%;        
      }
 
      .simbolos .container{
        margin-top: 2rem;
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
         padding-left: 15px;    
         padding-right: 15px;
      }
 
      .cardImg{
         width: 100%;
         display: flex;
         gap: 5rem;
         align-items: center;
         overflow-x: auto;
     }
 
     
      
      .pontosTuristicos .mapouter {
        margin-top: 0rem;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;      
        display: flex;
        gap: 0;
        width: 100%;
      }
 
      .mapouter h2{
        width: 100%;
        text-align: center;
      }
 
      .mapouter button{
         margin-left: 5rem;
      }
 
      .menu-municipio{
         width: 30rem;
         margin: 2rem 0;
         padding-left: 15px;    
         padding-right: 15px;
      }
 
      .container-municipio{
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column-reverse;
      }
}  

@media (width > 841px) and (width < 993px) {
    .municipio{
        display: flex;
        width: 100vw;  
        margin-top: -5%;
     }
     .municipioSobre .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
         
     }
     .municipioSobre .container p{
        font-size: 20px;   
        width: 50rem;
       
        
     }
     .municipioSobre .container img{ 
         width: 50rem;  
     }
 
     .historia .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
     }
     .historia .container p{
         font-size: 20px;
         width: 50rem;
         
      }
      .historia .carrousel{
        width: 50rem;      
      }
      
      .hino .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
      }
 
      .text-hino {
         flex-direction: column;
         font-size: 20px;
         width: 50rem;
      }
 
      .simbolos .container{
        margin-top: 2rem;
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
      }
 
      .cardImg{
         width: 740px;
         display: flex;
         gap: 5rem;
         align-items: center;
         overflow-x: auto;
     }
 
     
      
      .pontosTuristicos .mapouter {
        margin-top: 4rem;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         gap: 7rem;
         width: 100%;
      }
 
      .mapouter h2{
         width: 22rem;
         text-align: center;
      }
 
      .mapouter button{
         margin-left: 3.2rem;
      }
 
      .menu-municipio{
         width: 30rem;
         margin: 2rem 0;
      }
 
      .container-municipio{
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column-reverse;
      }

}

@media (width > 993px) and (width < 1200px) {
    .municipio{
        display: flex;
        width: 100vw;          
        margin-top: -5%;
     }
     .municipioSobre .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
         
     }
     .municipioSobre .container p{
        font-size: 20px;   
        width: 50rem;
       
        
     }
     .municipioSobre .container img{ 
         width: 50rem;  
     }
 
     .historia .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
     }
     .historia .container p{
         font-size: 20px;
         width: 50rem;
         
      }
      .historia .carrousel{
        width: 50rem;      
      }
      
      .hino .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
      }
 
      .text-hino {
         flex-direction: column;
         font-size: 20px;
         width: 50rem;
      }
 
      .simbolos .container{
        margin-top: 2rem;
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
      }
 
      .cardImg{
         width: 740px;
         display: flex;
         gap: 5rem;
         align-items: center;
         overflow-x: auto;
     }
 
     
      
      .pontosTuristicos .mapouter {
        margin-top: 4rem;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         gap: 7rem;
         width: 100%;
      }
 
      .mapouter h2{
         width: 22rem;
         text-align: center;
      }
 
      .mapouter button{
         margin-left: 3.2rem;
      }
 
      .menu-municipio{
         width: 30rem;
         margin: 2rem 0;
      }
 
      .container-municipio{
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column-reverse;
      }
}
    

/*  AJUSTAR O TAMANHO DA IMAGEM componete galeria */ 
.carrousel > div > div > div > div > div > div.image-gallery-slide.image-gallery-center > img{
    font-size: 22px;
    font-weight: 200;
    color: white;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    line-height: 0;
    white-space: nowrap;
    text-align: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: var(--fontA);
    height: 100%;
    object-fit: contain;
    max-height: 420px;
    max-width: 1200px;
    position: inherit;
    width: inherit;
  }