/* opções dos botoes */ 
.secretarias > div > a:last-child > button {
  border-radius: 0px 15px 15px 0px;
}

.secretarias > div > a:first-child > button {
  border-radius: 15px 0px 0px 15px;
}

.secretarias{
 
  margin-top: 5%;
  /* reduzir espçamento button */  
  font-size: 0;
  text-align: left;
  margin-bottom: 2%;

}

.pagObras{
  margin-left: 10vw;
  margin-bottom: -3vw;
  margin-top: -1vw;
  font-size: 32px;
  color: var(--mainColor);
}  

.secretarias > div:first-child button {
  top: -50px;
  min-width: 100px;
  height: 50px; 
  padding-left: 15px;
  padding-right: 15px;

  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
}

.secretarias > div:first-child button:hover{
  background-color: var(--mainColor);
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.menuObras{
  display:flex;
  margin-top: 2rem;
  margin-left: 10vw;
  margin-right: 6vw;
  text-align: center;
}

.menuObras div{
  margin-bottom: 20px;
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;
}

.menuObras div:first-child{
  max-height: 65px;
  max-width: 220px;
  min-width: 110px;
  display: flex;
}

.menuObras button {
  top: -50px;
  bottom: 50px;
  min-width: 36px;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
  
}

.menuObras div:last-child button{
  min-width: 120px;
}

.menuObras button:hover{
  background-color: var(--mainColor);
  border-color: var(--mainColor2);
  color: var(--textColor2);
}


.secretarias > div:first-child {
  margin-left: 10%;
  margin-right: 14%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}


.secretariasInfo .primeiroBotao{
  
  max-width : 670px;
  max-height: 590px; 
  width : 52vw;
  height: 45.7vw;
  min-width : 340px;
  min-height: 300px; 
  margin-left: 0%; 
  margin-right: 0%; 
}

.obrasInfo {
  margin-top: -3%;
}
