.card-container{
    width: 100%;
    height: auto; 
}

.card-container .title{
    text-align: center;
    padding-right: 60%;
}

.card-container .title span{
    font-size: 2rem;
    background-color: var(--textColor);
    padding: 10px 30px;
    color: var(--textColor2);
    font-weight: 400;
    border-radius: 40px 10px;
}

@media( min-width:  766px){
    .card-container .tituloPagina{
        margin-left: 13%;
    }
}

.card{
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    column-gap: 4rem;

    margin-bottom: -10rem;
}

.card p{
    color: var(--textColor);
}

.card svg {
    margin-top: 2rem;
    font-size: 56px;

}

.card button {
    
    background: none;
    border: none;
    outline: none;
    margin: 1.5rem 0;
    background-color: var(--mainColor4);
    border-radius: 30px;
    padding: 10px 40px;
    width: auto;

}

.card button span{
    font-family: var(--fontA);
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--textColor2);
}
.card button svg {
    
    font-size: 1.2rem;
    margin-top: 0;
    margin-right: 0.4rem;
    color: var(--textColor2);
}


.Email{
    color: var(--textColor);
    background-color: var(--textColor2);
    margin-bottom: 2rem;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
    

    display: flex;
    flex-direction: column;
    width: 35%;
    justify-content: center;
    align-items: center;
}

.card h1{
    color: var(--textColor);
    font-size: 1.5rem;
    text-align: center;
    margin: 0;
}

.card span{
    color: #3E23E2;
    margin: 0;
    padding-bottom: 2rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.card p{
    padding: 5px 25px;
    text-align: justify;
    width: auto;
}

.TelefoneContato{
    color: var(--textColor);
    background-color: var(--textColor2);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    

    display: flex;
    flex-direction: column;
    width: 35%;
    justify-content: center;
    align-items: center;
}

.Ouvidoria{
    color: var(--textColor);
    background-color: var(--textColor2);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
    

    display: flex;
    flex-direction: column;
    width: 35%;
    justify-content: center;
    align-items: center; 
    text-align: center;
}

.Ouvidoria p {
    margin-top: 2rem;
}

.E-Sic{
    color: var(--textColor);
    background-color: var(--textColor2);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    width: 35%;
    justify-content: center;
    align-items: center;
}
.E-Sic p{
    margin-top: 2rem;
}
.containerEnd{
    width: 100vw; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10rem;
    flex-wrap: wrap;
    margin-top: 13rem; 
    margin-bottom: 25px; 
}
.cardEndereco{
    display: flex;
    width: 60%;
    max-width: fit-content;
}

.cardEndereco .cardsEnd{
    width: 300px;
    height: 210px;
    background-color: var(--textColor2);
    margin-right: 2rem;
    
    text-align: center;
    border-radius: 10px;  
}

 .cardsEnd p{
    padding-bottom: 10rem;
    padding-top: 0.5rem;
    width: 300px; 
    height: 80px;
    background-color: var(--textColor);
    border-radius: 10px 10px  0 0;  
   color: var(--textColor2);
    
}

.cardsEnd span{
    position: relative;
    top: 1rem;
    color: var(--textColor2);
}

.mapEndereco{    
    width: 40%;
    max-width: fit-content;
}

.tamannhoMapa iframe{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin-left: 2rem; 
  
}


.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
   
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 75%;
    margin: 0 auto;
    height: auto;
    text-align: center;
  }
   
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.modal-content h1{
    text-transform: uppercase;    
    font-family: var(--fontA);
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
   
  }

 .modal-content .table th,
  .table td {
    text-align: left;
  }

  .table td {
    font-size: 1rem;
   
  }

 
 .modal-content .table th {
    background-color: var(--mainColor4);
    color: var(--textColor2);
    font-family: var(--fontA);
    font-size: 1.3rem;
    font-weight: 600;
  }

  .modal-content .table td {
    font-family: var(--fontA);
    font-size: 20px;
  }
  
 .modal-content .table tbody tr:nth-child(even) {
    
    background-color: #f9f9e9;
  }
 
 .modal-content .table tbody tr:hover {
   
    background-color: #ebebeb;
  }

  .modal-content button {
    font-family: var(--fontA);
    font-size: 1.2rem;
    background: var(--mainColor4);
    transition: 0.3s all;
    margin-top: 2rem;
    width: 12rem;
    height: 3rem;
    cursor: pointer;
    color: var(--textColor2);
  }
  
  .modal-content button:hover{
  
    /*
        width: 12rem;
        height: 3rem;
    */ 
    font-size: 1.2rem;
    border: none;
    background: var(--mainColor);
  }

.titleCard{
    border-bottom: 2px solid var(--textColor);
}

@media( max-width:  320px){
    .card{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }

    .card-container .title{
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 1.5rem;
    }

    .Email{
        width: 90%;      
    }
    .TelefoneContato{
        text-align: center;
        width: 90%; 
    }

    .Ouvidoria{
        margin-bottom: 2rem;
        width: 90%;
    }

    .E-Sic{
        width: 90%;
    }
    

    .containerEnd{
        margin: 0;
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;    
        flex-wrap: wrap;
        margin-top: 15rem;  
    }

    .cardEndereco{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 4rem;
        margin-right: 2rem;
    }
    .cardEndereco h1{
        width:200px;
       font-size: 1.5rem;     
    }

    .titleCard{
      height: 30px;
      width: 120px;
      padding-bottom: 2rem;
    }
       
    .cardEndereco .cardsEnd{
        width: 300px;
        height: 210px;
        margin: 2rem 0;        
        text-align: center;
        border-radius: 10px;  
    }


    .mapEndereco{     
        margin: 2rem 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;        
    }

    .tamannhoMapa iframe{
        width: 300px;
        height: 300px;
        border-radius: 50%;
        margin-left: 2rem;      
    }
}

@media (min-width: 321px) and (max-width: 481px) {
    .card{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .card-container .title{
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 1.9rem;
    }

    .Email{
        width: 87%;      
    }
    .TelefoneContato{
        text-align: center;
        width: 87%; 
    }

    .Ouvidoria{
        margin-bottom: 2rem;
        width: 87%;
    }

    .E-Sic{
        width: 87%;
    }

    .containerEnd{
        margin: 0;
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
       align-items: center;
      
        flex-wrap: wrap;
        margin-top: 12rem;  
    }

    .cardEndereco{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 4rem;
        margin-right: 2rem;
    }
    .cardEndereco h1{
        width:200px;
       font-size: 1.5rem;     
    }

    .titleCard{
      height: 30px;
      width: 120px;
      padding-bottom: 2rem;
    }
    
    
    .cardEndereco .cardsEnd{
        width: 300px;
        height: 210px;
        margin: 2rem 0;        
        text-align: center;
        border-radius: 10px;  
    }


    .mapEndereco{ 
       
       
        margin: 2rem 0;
        width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
       
        
    }

    .tamannhoMapa iframe{
        width: 300px;
        height: 300px;
        border-radius: 50%;
       
      
    }
}

@media (min-width: 482px) and (max-width: 769px) {
    .card{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Email{
        width: 87%;      
    }
    .TelefoneContato{
        text-align: center;
        width: 87%; 
    }

    .Ouvidoria{
        margin-bottom: 2rem;
        width: 87%;
    }

    .E-Sic{
        width: 87%;
    }

    .containerEnd{
        margin: 0;
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
       align-items: center;
      
        flex-wrap: wrap;
        margin-top: 12rem;  
    }

    .cardEndereco{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 4rem;
        margin-right: 2rem;
    }
    .cardEndereco h1{
        width:200px;
       font-size: 1.5rem;     
    }

    .titleCard{
      height: 30px;
      width: 120px;
      padding-bottom: 2rem;
    }
    
    
    .cardEndereco .cardsEnd{
        width: 300px;
        height: 210px;
        margin: 2rem 0;        
        text-align: center;
        border-radius: 10px;  
    }


    .mapEndereco{ 
       
       
        margin: 2rem 0;
        width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
       
        
    }

    .tamannhoMapa iframe{
        width: 300px;
        height: 300px;
        border-radius: 50%;
        margin-left: 2rem; 
      
    }

}

@media (width > 768px) and (width < 1024px) {

    .containerEnd{
        margin: 0;
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
       align-items: center;
      
        flex-wrap: wrap;
        margin-top: 15rem;  
    }
    .card-container .title {
       margin-top: 4rem;
    }

    .cardEndereco{
        display: flex;     
        justify-content: center;
        gap: 2rem;
        align-items: center;
        margin-left: 6rem;
        margin-right: 0rem;
    }
    .cardEndereco h1{
        width:200px;
       font-size: 1.5rem;     
    }

    .titleCard{
      height: 30px;
      width: 120px;
      padding-bottom: 2rem;
    }
    
    
    .cardEndereco .cardsEnd{
        width: 300px;
        height: 210px;
        margin: 2rem 0;        
        text-align: center;
        border-radius: 10px;  
    }


    .mapEndereco{ 
       
       
        margin-left: 6rem;
        margin-right: 0rem;
        width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
       
        
    }

    .tamannhoMapa iframe{
        width: 300px;
        height: 300px;
        border-radius: 50%;
        margin-left: 2rem; 
      
    }
}


@media (width > 1024px) {

    .cardEndereco{
        margin-left: -16rem;
        margin-right: 0rem;
    }   
    .mapEndereco{ 
              
        margin-left: 0rem;
        margin-right: 0rem;  
    }
}