
.pagPesquisa  {
  background-color: var(--mainColor1);
  /* tirar virgula e centralizar */  
  font-size: 0;
  text-align: center;
  position: relative;
  top: 20px;
}

.dadosPesquisa{
  /* espaçamento inicial e final */ 
  padding-top: 3%; 
  padding-bottom: 2%;  
}

.menuPesquisa {  
  margin-left: 10%;
  margin-right: 14%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;

}

.menuPesquisa button {
  position: relative;
  width: 120px;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  cursor: pointer;
  
  padding-left: 15px;
  padding-right: 15px;
  min-width: 120px;
}

.menuPesquisa button:hover{
  background-color: var(--mainColor);
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.menuPesquisa button:last-child {
  border-radius: 0px 15px 15px 0px;
}
.menuPesquisa button:last-child:hover{
  border-radius: 0px 15px 15px 0px;
}
.menuPesquisa button:first-child {
  border-radius: 15px 0px 0px 15px;
}
.menuPesquisa button:first-child:hover{
  border-radius: 15px 0px 0px 15px;
}


/*  links  */ 

.pesquisaLinks{  
  margin-top: 0%;
  margin-bottom: 2%;
}

.pesquisaLinks button{
  
  width : 160px;
  height: 170px; 

  margin: 10px;
 
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  color: var(--textColor);

  line-height: 1;
  font-size: 20px;
  cursor: pointer;
  border: var(--mainColor) 3px;
  border-style:solid; 
}

.pesquisaLinks button:hover{
  background-color: var(--mainColor);
  border-radius: 15px 15px 15px 15px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.pesquisaLinks button img{
  
  width : 100px;
  height: 100px; 
  padding: 10px;
  position: relative;
  top: -4px;
 
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
}

/*  PERGUNTAS  */ 
.container-pergunta{
  left: -2%;
  position: relative;
  margin-top: 0%;
}
.content-perguntas {    
  width: 85%;
}

/*  Contatos  */ 
.pesquisaContatos{
  left: -2%;
  position: relative;
  
  margin-top: 0%;
  margin-bottom: 2%;
}

.pesquisaFormatoNoticias  { 
  margin-top: 0%;
  text-align: center;
  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
    margin-left: 10%; 
  margin-right: 10%; 
  */       
  display: flex;
  flex-direction: row;
  margin-left: 10%;
  margin-right: 14%;
}

.pesquisaFormatoNoticias > div > a{
  margin-bottom: 10px;
  display: inline-flex;
}

.pesquisaFormatoNoticias  button{
  
  width : 24.8vw;
  height: 21.7vw; 
  max-width : 320px;
  max-height: 280px; 
  min-width : 340px;
  min-height: 150px;

  margin: 5px;
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;

  line-height: 1;
  cursor: pointer;
}

.pesquisaFormatoNoticias   p {
  position: relative;

  left: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  text-align:justify;

  white-space: break-spaces;

  height: 86px;
  width: 95%;

  top: -135px;
  line-height: 1;
  color: var(--textColor2);
  text-shadow: 1px 1px 2px black;
}

.pesquisaFormatoNoticias  button img{
  
  width : 100%;
  height: 100%; 
  object-fit: cover;
  
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  color: var(--textColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  filter: brightness(0.8);
}

/* Indentificação das secretarias*/  
.pesquisaFormatoNoticias div button  p:last-child {
  margin-top: 10px;
  margin-left: -10px;

  background-color: var(--mainColor);
  border-radius: 0px 15px 0px 15px;

  padding: 6px;
  height: 35px;
  width: 52%;
}


@media (max-width: 499px) {

  .pesquisaFormatoNoticias  p {  
    height: 76px;
    top: -14vh;
  }
  .pesquisaFormatoNoticias{  
    margin-left: 0%; 
    margin-right: 0%; 
  }
}

@media (max-width: 399px) {
  .pesquisaFormatoNoticias  p {  
    height: 62px;
    top: -13.5vh;
  }
}


@media (max-width: 376px) {
  .pesquisaFormatoNoticias  p {  
    height: 44px;
    top: -14vh;
  }
}


.spacePesquisa{
  height: 50px;
}

/* CONCURSO */  
.pesquisaConcurso   {

  margin-left: 10%;
  margin-right: 10%;  
  margin-top: 0%;
  text-align: center;
  /*  pra não aparecer a virgula  */ 
  color: var(--mainColor2);  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.pesquisaConcurso button{
  width: 250px;  
  min-width: 250px;
  min-height: 300px;  

  margin: 10px;
  background-color: var(--mainColor2);
  border-radius: 15px 15px 15px 15px;
  cursor: default;
  border: var(--textColor) 0.8px;
  border-style:solid; 
}

.pesquisaConcurso  p {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;

  font-size: 22px;
  
  line-height: 1.2;
  color: var(--textColor);
  /*
  text-shadow: 0.5px 0.5px 0.5px rgb(170, 160, 160);
  */ 
  text-align: left;
}

.pesquisaConcurso  p:nth-child(1) {
  top: -40px;
  min-height: 120px;  
}

.pesquisaConcurso  p:nth-child(2) {
  font-size: 18px;
}
.pesquisaConcurso  p:nth-child(3) {
  font-size: 15px;
}

.pesquisaConcurso a {
  font-size: 20px;
  color: var(--textColor2);
  line-height: 1.2;

  position: relative;
  top: 30px;
  padding: 12px 70px;

  background-color: var(--mainColor);
  border-radius: 10px;
  border-style:solid; 
  cursor: pointer;
}

/* PESSOAS */  

.pesquisaPessoas   {

  margin-left: 10%;
  margin-right: 10%;  
  margin-top: -2%;
  text-align: center;
  /*  pra não aparecer a virgula  */ 
  color: var(--mainColor2);  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;
  
  
}

.pesquisaPessoas button{

  width: 250px;  
  min-width: 250px;

  /*
  max-width : 320px;
  max-height: 280px;   
  min-height: 150px;
  */      

  margin: 10px;
  background-color: var(--mainColor);
  border-radius: 15px 15px 15px 15px;
  
}

.pesquisaPessoas button:first-child{
  cursor: pointer;
}

.pesquisaPessoas    p {
  position: relative;

  padding-bottom: 10px;
  top: 0.5vh;
  bottom: 1vh;

  font-size: 22px;
  
  line-height: 1.2;
  color: var(--textColor2);
  text-shadow: 1px 1px 2px black;
}

.pesquisaPessoas   button img{
  position: relative;
  width : 100%;

  object-fit: cover;
      
  border-radius: 15px 15px 15px 15px;
  filter: brightness(0.8);
  filter: grayscale(1);
}

.pesquisaPessoas button:first-child img{
  filter: grayscale(0);
}

.pesquisaPessoas   button img:hover{
  filter: grayscale(0);
}


@media (max-width: 499px) {
  .menuPesquisa {  
    margin-left: 5%;
    margin-right: 5%;
  
  }
  .pesquisaFormatoNoticias {  
    margin-left: 5%;
    margin-right: 5%;
  }  
  .pesquisaPessoas {  
    margin-left: 15%;
    margin-right: 15%;
  }  
  .pesquisaConcurso {  
    margin-left: 15%;
    margin-right: 15%;
  } 
}



.videoCard   {

  margin-left: 10%;
  margin-right: 8%;  
  margin-top: 0%;
  margin-bottom: 40px;
  text-align: center;
  /*  pra não aparecer a virgula  */ 
  color: var(--mainColor2);  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;
}

.videoCard button{
  width: 250px;  
  min-width: 250px;
  min-height: 300px;  

  margin: 10px;
  background-color: var(--mainColor2);
  border-radius: 15px 15px 15px 15px;
  cursor: default;
  border: var(--textColor) 0.8px;
  border-style:solid; 
}

.videoCard  p {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;

  font-size: 18px;
  
  line-height: 1.3;
  color: var(--textColor);
  /*
  text-shadow: 0.5px 0.5px 0.5px rgb(170, 160, 160);
  */ 
  text-align: left;
}

.videoCard  p:nth-child(1) {
  top: -40px;
  font-size: 21px;
}


.videoCard a {
  font-size: 20px;
  color: var(--textColor2);
  line-height: 1.2;

  position: relative;
  top: 30px;
  padding: 12px 70px;

  background-color: var(--mainColor);
  border-radius: 10px;
  border-style:solid; 
  cursor: pointer;
}

