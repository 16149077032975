/* 
 *{
      padding: 0;
      border: 0;
      box-sizing: border-box;
   }
*/

 .main-organograma{
  
   display: flex;
   justify-content: center;

   margin-left: 6%;
   margin-right: 6%;  
   margin-bottom: 3%;  
   background-color: var(--mainColor2);  
} 
 .container-organograma{
   width: auto;
   height: fit-content;
   overflow-x: scroll;
   white-space: nowrap; 
 
   padding: 2rem 3rem 2rem 1rem; 
}
 
.ajustarPrefeito{
   margin-left: 62rem;
   bottom: 27.8rem;
}
