
@media (max-width: 976px) {
  .nomeInicial {
    margin-left: 20%;
  }
}

@media (max-width: 499px) {
  .nomeInicial {
    font-size: 24px;
    margin-left: 18%;
  }
}




.nomeInicial{
  font-size: 22px;
        
  margin-left: 10%;
  margin-bottom: 20px;
  color: var(--mainColor);
  font-size: 32px;
}  

.concursoCard   {

  margin-left: 8%;
  margin-right: 8%;  
  margin-top: 2%;
  margin-bottom: 40px;
  text-align: center;
  /*  pra não aparecer a virgula  */ 
  color: var(--mainColor2);  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;
}

.concursoCard button{
  width: 250px;  
  min-width: 250px;
  min-height: 300px;  

  margin: 10px;
  background-color: var(--mainColor2);
  border-radius: 15px 15px 15px 15px;
  cursor: default;
  border: var(--textColor) 0.8px;
  border-style:solid; 
}

.concursoCard  p {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;

  font-size: 22px;
  
  line-height: 1.2;
  color: var(--textColor);
  /*
  text-shadow: 0.5px 0.5px 0.5px rgb(170, 160, 160);
  */ 
  text-align: left;
}

.concursoCard  p:nth-child(1) {
  top: -40px;
  min-height: 120px;  
}

.concursoCard  p:nth-child(2) {
  font-size: 18px;
}
.concursoCard  p:nth-child(3) {
  font-size: 15px;
}

.concursoCard a {
  font-size: 20px;
  color: var(--textColor2);
  line-height: 1.2;

  position: relative;
  top: 30px;
  padding: 12px 70px;

  background-color: var(--mainColor);
  border-radius: 10px;
  border-style:solid; 
  cursor: pointer;
}


.concursoOpt button {
  top: -50px;
  min-width: 170px;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
}

.concursoOpt button:first-child {
  border-radius: 15px 0px 0px 15px;
}

.concursoOpt button:last-child {
  border-radius: 0px 15px 15px 0px;
}

.concursoOpt button:hover{
  background-color: var(--mainColor);
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.concursoOpt {
  margin-left: 8%;
  margin-right: 14%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}


@media (max-width: 1290px) {
  .concursoOpt {
    margin-right: 10vw;
  }  
}
