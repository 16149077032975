/* intermediario*/ 
.intermediario{
  margin-top: 25px;
  padding-top: 20px;
  text-align: center;
  height: 150px;
}

.intermediario button{
  width: 170px;
  height: 70px; 
  
  margin-left: 10px;
  margin-right: 10px;

  font-size: 25px;
  
  background-color: var(--mainColor);
  border-radius: 15px 15px 15px 15px;
  border-color: var(--mainColor);
  border-style: none;
  color: var(--textColor2);
  
  margin: 20px;
  cursor: pointer;
}

.intermediario button:hover{
  background-color: var(--textColor2);
  color: var(--mainColor);
  border-color: var(--textColor);
  border-style:solid;
  
}


@media (max-width: 635px) {

  .intermediario button{
    width: 160px;
    height: 60px; 
    margin: 5px;
  }
  .intermediario{
    padding-top: 30px;
  }
}

@media (max-width: 515px) {

  .intermediario{
    padding-top: 2px;
  }
}

/* intermediario*/ 

/* local*/ 


.local {
  text-align: left;
  max-height: 500px;
  /* 
  margin-top: 30px; 
  */   
  color: white;
  background-color: var(--mainColor);
  display: flex;
}

.localInfo {
  width: 45%;
  min-height: 300px;
  max-height: 45vh;
  position: relative;
  margin-top: 4%;
  margin-left: 3%; 
  margin-right: 3%; 
  margin-bottom: 3%; 

  overflow-x: auto;

}

.localInfo p:first-child{
  font-size:xx-large;
  margin-bottom: 3%; 
}

.localGaleria{
  text-align: center;
  max-width: 45%;
  /* 
  border: black;
  border-style:solid;
  */  
  margin-top: 2%;
  margin-left: 2%; 
  margin-right: 2%; 
  margin-bottom: 2%; 
  margin: auto;
}


.localGaleria img{
  width : 100%;
  height: 100%; 
  max-width: 650px;
}

/*  AJUSTAR O TAMANHO DA IMAGEM HOME */ 
.localGaleria > div > div > div > div > div > div.image-gallery-slide.image-gallery-center > img{
  font-size: 22px;
  font-weight: 200;
  color: white;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  line-height: 0;
  white-space: nowrap;
  text-align: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: var(--fontA);
  height: 100%;
  object-fit: contain;
  max-height: 420px;
  max-width: 1200px;
  position: inherit;
  width: inherit;
}


.localFotos{
  width : 36vw;
  height: 29vw; 
  min-width: 300px;
  min-height: 280px;
  max-width: 500px;
  max-height: 450px;
}


/*  CONTAINER local  */ 


@media (min-width: 1491px) {

  .local {
    height: 27rem;
  }
}


@media (max-width: 850px) {
  .localInfo {
    font-size: medium;
  }  
  .localInfo p:first-child{
    font-size:x-large;
  }  
}

@media (max-width: 600px) {
  .local {
    height: 68vw;
  }
}


/* local*/ 

/* noticias*/ 


.noticias {
  margin-top: 75px;
  width: 100%;
  /* 
  width: 98%;
  margin-left: 1%; 
  margin-right: 1%; 
  */  
  background-color: var(--mainColor2);
  /* reduzir espçamento button */  
  font-size: 0;
  text-align: center;
  
}

.videos {
  bottom: 20px;
  position: relative;
  display: none;
  left: 15%;  
}

.videos > iframe {
  width: 53vw;
  height: 30vw;
  
}

.videoSeletor{
  width: 20%;
  height: 30vw;
  position: relative;
  left: 15px;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
}

.videoSeletor iframe{
  width: 100%;
  height: 100%;  
  pointer-events: none;
}

.videoSeletor button{
  width: 100%;
  height: 40%;
  position: relative;
  opacity:100%;
  border: transparent;
}


.noticias li button {
  top: -50px;
  position: relative;
  width: 120px;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  cursor: pointer;
}

.noticias li button:first-child {
  width: 200px;
  background-color: var(--mainColor);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
}


.noticias li button:last-child {
  width: 200px;
  border-radius: 15px 15px 15px 0px;
}

.noticias li button:hover{
  border-radius: 15px 15px 15px 0px;

  background-color: var(--mainColor);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.noticias li button:last-child:hover{
  width: 200px;
  border-radius: 15px 15px 15px 0px;
}



@media (max-width: 750px) {
  .videos {
    transform: scale(1.2);
    margin-top: 3%;
    border-bottom: 15px solid var(--mainColor2);
  }
}

 /*  NOVAS NOTICIAS (PADRÃO) */ 

 .noticiasInfo  {
 
  text-align: center;
  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
    margin-left: 10%; 
  margin-right: 10%; 
  */       
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 40px;
}

.noticiasInfo  button{
  
  width : 24.8vw;
  height: 21.7vw; 
  max-width : 320px;
  max-height: 280px; 
  min-width : 340px;
  min-height: 150px;

  margin: 5px;
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;

  line-height: 1;
  cursor: pointer;
}

.noticiasInfo   p {
  position: relative;

  left: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  text-align:justify;

  white-space: break-spaces;

  height: 86px;
  width: 95%;

  top: -135px;
  line-height: 1;
  color: var(--textColor2);
  text-shadow: 1px 1px 2px black;
}

.noticiasInfo  button img{
  
  width : 100%;
  height: 100%; 
  object-fit: cover;
  
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  color: var(--textColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  filter: brightness(0.8);
}

/* Indentificação das secretarias*/  
.noticiasInfo button  p:last-child {
  margin-top: 10px;
  margin-left: -10px;

  background-color: var(--mainColor);
  border-radius: 0px 15px 0px 15px;

  padding: 6px;
  height: 35px;
  width: 52%;
}
@media (max-width: 499px) {

  .noticiasInfo  p {  
    height: 76px;
    top: -14vh;
  }
  .noticiasInfo{  
    margin-left: 0%; 
    margin-right: 0%; 
  }
}

@media (max-width: 399px) {
  .noticiasInfo  p {  
    height: 62px;
    top: -13.5vh;
  }
}


@media (max-width: 376px) {
  .noticiasInfo  p {  
    height: 44px;
    top: -14vh;
  }
}

 /*  NOVAS NOTICIAS (PADRÃO) FIM*/ 

/* noticias*/ 

/* options*/ 

/* opções dos botoes */ 
.options > div > button:last-child {
  border-radius: 15px 15px 15px 0px;
}

.options {
  /* 
  width: 98%;
  height: 120px;  
  margin-left: 1%; 
  margin-right: 1%; 
  */  
  background-color: var(--mainColor2);
  margin-top: 2%;
  /* reduzir espçamento button */  
  font-size: 0;
  text-align: center;
  
}

.options button {
  top: -50px;
  position: relative;
  width: 120px;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
}

.options > div:first-child > button:first-child{
  background-color: var(--mainColor);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.optionsInfo button:hover{
  background-color: var(--mainColor);
  border-radius: 15px 15px 15px 15px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.options > div > button:hover{
  background-color: var(--mainColor);
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.optionsInfo{

  margin-left: 15%; 
  margin-right: 15%; 


  margin-top: 1%;
  text-align: center;
  background-color: var(--mainColor2);  
  
  /*
  border: black;
  border-style:solid; 
  */   
}

.optionsInfo button{
  
  width : 160px;
  min-height: 160px; 

  margin: 10px;
 
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;

  line-height: 1;
  font-size: 20px;
  cursor: pointer;
}

.optionsInfo  p {
  position: relative;

  top: 4%;
  font-size: 22px;
  height: 50px;
  
  line-height: 1;
}

.optionsInfo button img{
  
  width : 100px;
  height: 100px; 
  padding: 10px;
  position: relative;
  
  top: 8px;
 
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
}

@media (max-width: 1200px) {
  .options {
    margin-top: 7%;    
  }
}

@media (max-width: 960px) {
  .optionsInfo{  
    margin-left: 0%; 
    margin-right: 0%; 
  }
}

@media (max-width: 615px) {
  .optionsInfo button{  
    width : 140px;
  }
}

/* options*/ 

/* servidores*/ 

.servidores {
  height: 28rem;
  display: flex;
  align-items: center;
  overflow-x: auto;
  gap: 20px;
  margin-left: 1%;
}

.servidoresCard{
  width: 200px;
  height: 300px;
  min-width: 200px;
  text-align: center;
  margin-left: 3%;
  margin-right: 0.5%;  
  background-color: var(--mainColor2);
  border: 3px var(--mainColor3);
  border-radius: 15px 15px 15px 15px;
  border-style:solid;
}


.servidoresCard p {
  position: relative;
  top: 1vh;
  color: var(--mainColor);;
  font-size: large;
}

.servidoresCard img{
  position: relative;
  width : 80%;
  height: 80%; 
  top: 10px;
  border-radius: 15px 15px 15px 15px;
  object-fit: cover;
}

/* servidores*/ 
