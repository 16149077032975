
.obraEspecifica{
  /* 
  width: 98%;
  height: 120px;  
  margin-left: 1%; 
  margin-right: 1%; 
  background-color: var(--mainColor2);
  */  
  
  margin-top: 10%;
  font-size: 0;
  text-align: left;
  margin-bottom: 2%;
  
  /*   bloquear ficar página rolando */
  overflow: hidden;
  margin-bottom: 5%;

}


.ntEspTitulo{
  margin-left: 10vw;
  margin-right: 35vw;
  margin-bottom: -7vw;
  margin-top: -1vw;
  color: var(--mainColor);
  font-size: 40px;
  min-height: 90px;
}  




.maisNoticias{

  max-width: 310px;
  min-width: 310px;
  width: 33vh;
  height: 50px; 
     
  position: relative;

  left: 66vw;
  margin-top: 0px;

  text-align: center;   
  font-size: 22px;
  
  background-color: var(--mainColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
}



.obraEspecificaInfo {

  margin-left: 10%; 
  margin-right: 6%; 
  margin-top: 1%;
  text-align: center;
  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;
  
  
}

.textoNoticiaEspecifica{
  font-size: 22px;
  text-align: justify;
  
  max-width : 1000px;
  min-width : 340px;
    
  line-height: 1.6;
  color: var(--mainColor);
  margin-top: 2rem;
  
}  


@media (max-width: 1620px) {
  .maisNoticias{
    min-width: 220px;
    width: 20vh;       
    position: relative;
    margin-left: 7vw;
    top: -5rem;
  }
}



@media (max-width: 1290px) {
  .obraEspecificaInfo {  
    flex-direction: column;
  }

  .ntEspTitulo{
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 5vw;
    line-height: 1.2;
  }  

  .maisNoticias{
    max-width: 80%;
    width: 100%;
    margin-left: -55vw;
    right: 10vw;
    top: 0vw;   
  }
}

@media (max-width: 499px) {

  .ntEspTitulo{
    font-size: 32px;
    margin-left: 14vw;
    margin-right: 10vw;
    margin-top: 5vw;
    line-height: 1.2;
  }  

  .obrasInfoEspecifica{
    font-size: 20px;  
  }
 
  .textoNoticiaEspecifica {
    font-size: 20px;
    min-width : 300px;
  }
}

.soObra{

  width : inherit;
  
}

.soObraX li button {
  width: 120px;
}

.lonha > div > p {
  border-bottom: var(--textColor) solid 2px;
}

.ObraEtapa{
  margin-top: -70px;
  color: var(--textColor2);  
}

.ObraEtapa > div >  div > div > div > div >  p {
  color: var(--textColor);
  top: 20px;
  left: 5px;
}

.ObraEtapa > div >  div > div > div > div > a > p {
  color: var(--textColor2);
  top: 0px;
  left: 0px;
  width: auto;
}

.ObraEtapa > div >  div > div > div > div a {
width: auto;
}

/* geral*/ 
.ObraAnexos {

  margin-left: 2%;
  margin-right: 2%;  
  margin-top: -1rem;
  text-align: left;
  gap: 20px;

  color: var(--textColor2);  
  padding-bottom: 20px;

  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: column;

  background-color: var(--mainColor2);
  border-radius: 15px 15px 15px 15px;  
  box-shadow: var(--mainColor) 0px 0px 0px 0.5px;

  
}

.ObraAnexos p {
  position: relative;
  margin-left: 5vh;
  margin-right: 5vh;
  margin-bottom: 5px;

  line-height: 1.2;
  color: var(--textColor);
  font-size: 20px;  
  margin-bottom: 50px;
}

.ObraAnexos button {
  cursor: pointer;
  position: relative;
  float: right;
  
  right: -20px;
  top: 6px;
  width: 160px;
  height: 35px;
  
  line-height: 1.2;
  background-color: var(--mainColor);
  color: var(--textColor2);
  border-radius: 10px 10px 10px 10px;  
  
  font-size: 20px;
  margin-right: 10px;
  margin-top: auto;
}

.ObraAnexos:nth-child(2) button {
  cursor: pointer;
  position: relative;
  float: right;
  
  right: -20px;
  top: 2px;
  width: 160px;
  height: 35px;
  
  line-height: 1.2;
  background-color: var(--mainColor);
  color: var(--textColor2);
  border-radius: 10px 10px 10px 10px;  
  
  margin-top: 10px;
}

@media (max-width: 499px) {
  .ObraAnexos p {
    font-size: 18px;  
  }
 
  .ObraAnexos button{
    font-size: 20px;
    margin-right: 10px;
    margin-top: -5px;
  }
  .ObraAnexos:nth-child(2) button{
    margin-top: 10px;
  }
}

@media (min-width: 500px) {
  .ObraAnexos button{
    font-size: 20px;
  }
}

.cadastroDocInterno p, .cadastroDocInterno span {
  display: flex;
  position: relative;
  margin-left: 15px;
  margin-right: 10px;
  font-size: 20px;
  line-height: 1.2;
  /* color: var(--textColor); */
  text-align: left;
  /* top: 20px; */
  /* left: 5px; */
}


.obrasEspecificaInfo {

  margin-left: 1%; 
  margin-right: 1%; 
  margin-top: -16px;
  text-align: center;
  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;   
}



.obrasEspecificaInfo > div:first-child{
    max-width: 65%;
}

.obrasEspecificaInfo > div:last-child  button{
  
  width : 24.8vw;
  height: 21.7vw; 
  max-width : 400px;
  max-height: 350px; 
  min-width : 340px;
  min-height: 150px;

  margin: 16px;
    
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
}


/* classe novo botão ver mais (mais obras)*/  
.obrasEspecificaInfo > div:last-child  a:last-child button{
  
  width : 24.8vw;
  max-width : 400px;
  min-width : 280px;
  
  height: 1.7vw; 
  max-height: 350px; 
  min-height: 50px;

  margin: 16px;
    
  font-size: 22px;
  background-color: var(--mainColor);
  border-color: var(--mainColor);
  color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
      
}




.obrasEspecificaInfo div:last-child p {
  position: relative;

  left: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  text-align:left;

  white-space: break-spaces;

  height: 86px;
  width: 95%;

    /*corrigido resolução texto
  top: -14vh;
  */  
  top: -135px;  
  line-height: 1;
  color: var(--textColor2);
  text-shadow: 1px 1px 2px black;

  margin-left: 0vw;  
}

.obrasEspecificaInfo div:last-child p:last-child{
  margin-top: 9px;
  margin-left: -10px;

  background-color: var(--mainColor);
  border-radius: 0px 15px 0px 15px;

  padding: 6px;
  height: 35px;
  width: 60%;
}

.obrasEspecificaInfo button img{
  
  width : 100%;
  height: 100%; 
  object-fit: cover;
  
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  color: var(--textColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  filter: brightness(0.8);
}



@media (max-width: 1290px) {
  .obrasEspecificaInfo > div:first-child{
    max-width: 100%;
  }
  .obrasEspecificaInfo {  
    flex-direction: column;
  }


  .obrasEspecificaInfo > div:last-child  button{
    margin: 35px 16px 0px 16px;
  }


  .obrasEspecificaInfo > div:last-child  a:last-child button{
    max-width: 100%;
    width: 70vw;
    left: 10vw;
    top: 0vw;   
  }
}


@media (max-width: 499px) {

  .obrasEspecificaInfo > div:last-child  button{
    min-width : 320px;
    margin-left: 0rem;
    margin-right: 0vw;
  }

  .obrasEspecificaInfo div:last-child p {
    line-height: 1;
    font-size: 20px;
    height: 79px; 
    margin-top: 9px;
  }

  .obrasEspecificaInfo div:last-child p:last-child{
    height: 30px;
  }

  .obrasEspecificaInfo > div:last-child  a:last-child button{
    margin-left: 0vw;
  }
}

@media (max-width: 399px) {
  .obrasEspecificaInfo div:last-child p {  
    margin-top: 16px;
    height: 56px; 
  }
  .obrasEspecificaInfo div:last-child p:last-child{
    margin-top: 29px;
    height: 27px;
  }
  .obrasEspecificaInfo > div:last-child  button{
    min-width : 300px;
    margin-left: 0rem;
  }
  .obrasEspecificaInfo > div:last-child  a:last-child button{
    margin-left: 0vw;
  }
}

@media (max-width: 376px) {
  .obrasEspecificaInfo div:last-child p {  
    margin-top: 16px;
    height: 56px; 
  }
  .obrasEspecificaInfo div:last-child p:last-child{
    height: 27px;
  }
  .obrasEspecificaInfo > div:last-child  button{
    min-width : 300px;
    margin-left: 0rem;
  }
  .obrasEspecificaInfo > div:last-child  a:last-child button{
    margin-left: 0vw;
  }
}

.menuSecretaria li button:last-child {
  width: 120px;
  border-radius: 15px 15px 15px 0px;
}
.menuSecretaria  li button:last-child:hover{
  width: 120px;
  border-radius: 15px 15px 15px 0px;
}