.pop-contentLateral {
    display: flex;
    justify-content: flex-end; /* Para posicionar à esquerda */
    width: 100%;
    height:auto;
    max-height: 30rem;
    object-fit: cover;
}



.pop-contentLateral img{
    width: 23rem;
    height: auto;
    object-fit: cover;
} 

.is-open{
    overflow: hidden;
}