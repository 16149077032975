
.nav-area{
  height: 50px;
  margin: auto;
  background-color: #004348;

  display: flex;
  text-align: center;
  cursor: pointer;
}

/* localização do menu */
.menus{
  /*  
  margin-top: 150px; 
  */
  margin-top: 2px; 
  display: inherit;
  text-align: center;
  
}

/* botao do menu */
.menu-items{
  color: white;  
  background-color: #004348;
  z-index: 1;
  height: 40px;
  /*  
  
  height: 30px;
  margin-top: -90px;
  border: black;
  border-style:solid; 
  */   
  
  bottom: 10px;
}

.menu-items button{
  
  margin-top: 1px;
  width: 150px;
  display: block;
  
  color: white;  
  background-color: transparent;
  flex-direction: row;  
  z-index: 1;
  font-size: 22px;
  border-color: transparent;
  cursor: pointer;
  
}

/* PARTE DOS SUBITENS  */
.menu-subitems{
  padding-top: 10px;
  height: 50px;
  color: white;  
  flex-direction: row;  
  background-color: #004348;
  z-index: 1;
  cursor: pointer;  
  
  /*MUDEI AQUI */
  /*
   overflow-y: auto;
  width: 150px;
  
  */
 
}


/*
   .menu-subitems a {
  width: 150px;
  display: flex;
}

  */


.menu-subitems:hover p{
  color: #004348;  
  flex-direction: row;  
  background-color: white;
  
}

.dropdown {
  display: none; 
}

.show {
 display: block;
 border: white 2px;
 border-style:none; 
}

@media (max-width: 1125px) {

  .show {
    display: block;
    border: white 3px;
    filter: brightness(1.2);    
    border-top-style: solid; 
    border-bottom-style: solid; 
   }

  .menu-items button{
    width: 24vw;
    min-width: 190px; 
  }
}