.image {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
 
  }
  
  .image.zoomed {
       background-color: red;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    margin: auto;
    max-width: 300%;
    max-height: 100%;
    cursor: zoom-out;
}

.inner{
  display: flex;
}

.carrousel{
  cursor: grap;
  overflow: hidden;
}


  