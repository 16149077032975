/* opções dos botoes */ 
.ptsTuristicos > div > a:last-child > button {
  border-radius: 0px 15px 15px 0px;
}


.ptsTuristicos > div > a:first-child > button {
  border-radius: 15px 0px 0px 15px;
}

.ptsTuristicos{
  /* 
  width: 98%;
  height: 120px;  
  margin-left: 1%; 
  margin-right: 1%; 
  background-color: var(--mainColor2);
  */  
  
  margin-top: 5%;
  /* reduzir espçamento button */  
  font-size: 0;
  text-align: left;
  margin-bottom: 2%;

}

.ptsTursTitulo{
  margin-left: 10vw;
  margin-bottom: -3vw;
  margin-top: -1vw;
  color: var(--mainColor);
  font-size: 32px;
}  

.ptsTuristicos > div:first-child button {
  top: -50px;
  min-width: 100px;
  height: 50px; 
  padding-left: 15px;
  padding-right: 15px;

  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
}

.ptsTuristicos > div:first-child button:hover{
  background-color: var(--mainColor);
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.ptsTuristicosMenu{
  display:flex;
  margin-left: 10vw;
  text-align: center;
}

.ptsTuristicosMenu div{
  margin-bottom: 20px;
  text-align: center;
}

.ptsTuristicosMenu button {
  top: -50px;
  bottom: 50px;
  min-width: 36px;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
  
}

.ptsTuristicosMenu div:last-child button{
  min-width: 120px;
}

.ptsTuristicosMenu button:hover{
  background-color: var(--mainColor);
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

@media (max-width: 960px) {
  .ptsTuristicosMenu{
    display:inline;
    position: relative;
    margin-left: 0vw;
    text-align: center;
    margin-top: -50%;
  }
}

  /*  AJUSTE 2 NOTICIAS DIREITA */ 
.ptsTuristicos > div:nth-child(2) > div:nth-child(2){
  height: 630px; 
  width : 52vw;
  overflow-y: hidden;
  overflow-x: hidden;
}

.ptsTuristicosInfo {
  
  /*
  margin-left: 10%; 
  margin-right: 10%; 
  */ 
  margin-left: 8%; 
  margin-right: 8%; 
  margin-top: 1%;
  text-align: center;
  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;
}

.ptsTuristicos > div:first-child {

  /*
  margin-left: 10%;
  margin-right: 14%;
  */ 
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}


.ptsTuristicosInfo .primeiroBotao{
  
  max-width : 670px;
  max-height: 590px; 
  width : 52vw;
  height: 45.7vw;
  min-width : 340px;
  min-height: 300px; 
  margin-left: 0%; 
  margin-right: 0%; 
}

.ptsTuristicosInfo > div > a{
  margin-bottom: -20px;
  display: inline-flex;
}

.ptsTuristicosInfo button{
  
  width : 24.8vw;
  height: 21.7vw; 
  max-width : 320px;
  max-height: 280px; 
  min-width : 320px;
  min-height: 150px;

  margin: 16px;
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;

  line-height: 1;
  cursor: pointer;
}

/* retirado por estar subindo em cima dos outros botoes*/  
/*
.ptsTuristicosInfo a:hover{
  transform: scale(1.08);
  transition: 2s;
}
*/  


.ptsTuristicosInfo  p {
  position: relative;

  left: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  text-align:justify;

  white-space: break-spaces;

  height: 86px;
  width: 95%;
  /*corrigido resolução texto
  top: -14vh;
  */  
  top: -135px;  
  line-height: 1;
  color: var(--textColor2);
  text-shadow: 1px 1px 2px black;
}

/* Indentificação das secretarias*/  
.ptsTuristicosInfo div button  p:last-child {
  margin-top: 10px;
  margin-left: -10px;

  background-color: var(--mainColor);
  border-radius: 0px 15px 0px 15px;

  padding: 6px;
  height: 35px;
  width: 60%;
}

.ptsTuristicosInfo button img{
  
  width : 100%;
  height: 100%; 
  object-fit: cover;
  
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  color: var(--textColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  filter: brightness(0.8);
}



@media (max-width: 1814px) {
  .ptsTuristicosInfo {  
    text-align: center;
  } 
}

@media (max-width: 1290px) {
  .ptsTuristicosInfo {  
    flex-direction: column;
  }
  .ptsTuristicos > div:nth-child(2) > div:nth-child(2){
    height: auto; 
    width : auto;
    overflow-y: hidden;
    overflow-x: hidden;
  }    
}


@media (max-width: 499px) {

  .ptsTuristicosInfo  p {  
    height: 76px;
    margin-top: 9px;
  }
  .ptsTuristicosInfo{
    margin: auto;
  }
}

@media (max-width: 399px) {
  .ptsTuristicosInfo  p {  
    height: 62px;
    margin-top: 23px;
  }
}


@media (max-width: 376px) {
  .ptsTuristicosInfo  p {  
    margin-top: 43px;
    height: 44px;
  }
}