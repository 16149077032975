.form-ouvidoria-container{
  margin-top: 5rem;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center; 
  flex-direction: column;
  
}
.form-ouvidoria-protocolo{
  display: flex;
  flex-direction: column;
  border: 1px solid #c0bfbfc7;
  width: 90%;
  border-radius: 7px;
}

.form-ouvidoria-protocolo h1{
 
  font-size: 30px;
  font-weight: 200;
  padding: 10px;

  width: 100%;
  background-color: var(--mainColor);
  border-radius: 5px 5px 0 0;
  color: var(--textColor2);
}

.form-group input{
 font-weight: 400;
 padding: 10px;
}

.form-group input[type=file]{
  margin-left: -10px;
  margin-bottom: 10px;
 }

.form-ouvidoria-protocolo p{
 
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  margin-left: 2rem;
  color: var(--mainColor);
}

.form-ouvidoria-protocolo button {
  margin-bottom: 2rem;
  border: none;
  background: none;
  margin-left: 2rem;

  background-color: var(--mainColor);
 
  padding: 10px;
  color: var(--textColor2);
  cursor: pointer;
  font-size: 18px;
  text-transform: uppercase;
  border-radius: 5px;
}

.container-sigilo-info option {
  font-size: 20px;
  font-weight: 200;
}

.form-ouvidoria-protocolo input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

.form-group{
  display: flex;
  flex-direction: column;
  
 width: 100%;
  /*
  border: black;
  border-style:solid; 
  */ 
}



.form-ouvidoria-protocolo input{
  background: none;
  border: none;

  width: 90%;
  border: 2px solid #c0bfbfc7;
  border-radius: 5px;
  padding: 10px;
  margin-left: 2rem;
  margin-bottom: 2rem;
  outline: none;

 
  font-weight: 600;
}


.form-ouvidoria-protocolo input:focus{
  padding: 10px;
  width: 90%;
  border: 2px #078d97 solid;
}

/* Criar manifestação */

.form-ouvidoria-content{
    border: 2px #c0bfbfc7 solid;
    margin-top: 3rem;  
    margin-bottom: 3rem;  
    border-radius: 10px;
    width: 90%; 
    color: var(--mainColor);
}

.form-ouvidoria-content select, .form-ouvidoria-content option{
  color: var(--mainColor);
}

input::placeholder{
 
  font-weight: 400;
  font-size: 20px;
}

.form-ouvidoria-content h2{
 
  font-size: 28px;
  font-weight: 200;
  padding: 10px;

  width: 100%;
  background-color: var(--mainColor);
  border-radius: 5px 5px 0 0;
  color: var(--textColor2);

}
.form-ouvidoria-margin{
  width: 100%;
  padding: 2rem;

}
.container-sigilo{
  
  gap: 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.form-ouvidoria-radio{
 
  display: flex;
  gap: 1rem;
}

.form-group input[type=text], input[type=number]{
  border: none;
  background: none;

  border-radius: 5px;
  padding: 5px;
  outline: none;
  border: 2px solid #c0bfbfc7;
  width: 90%;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 400;
  color: var(--mainColor);
}

.form-group select{
  width: 95%;
  height: 54px;
 
  font-size: 20px;
  font-weight: 200;

  border: none;
  background: none;

  border-radius: 5px;
  padding: 10px;
  
  outline: none;
  border: 2px solid #c0bfbfc7;
}


.container-sigilo-info .form-group{
  display: flex;
  flex-direction: column;
}
.container-sigilo-info .form-group input{
  width: 95%;
 
  border: none;
  background: none;

  border-radius: 5px;
  padding: 10px;
  
  outline: none;
  border: 2px solid #c0bfbfc7;
  color: var(--mainColor);
}

.container-sigilo-info .form-group input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

.container-sigilo-info .form-group input::placeholder{
  font-weight: 400;
}
.container-sigilo-info {
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.nameComplete{
 padding-top: 2rem;
}

#titulo{
  width: 97%;
  color: var(--mainColor);
}

.textArea-ouvidoria {
  margin-top: 2rem;
}
.textArea-ouvidoria textarea{
 
  font-size: 18px;
  border: none;
  background: none;
  border: 2px solid #c0bfbfc7;
  border-radius: 5px;
  padding: 10px;
  resize: none;
  color: var(--mainColor);
}
.form-ouvidoria-title {
  width: 100%;
}
.textArea-ouvidoria textarea::placeholder{
 
  font-weight: 400;
}

.form-documents{
  margin-top: 2rem;
}

.form-documents input{
  width: 100%;
  font-size: 18px;
  height: 2.5rem;
}

.form-btn{
  margin-top: 2rem;
  border: none;
  background: none;

  background-color: var(--mainColor);
 
  padding: 10px;
  color: var(--textColor2);
  cursor: pointer;
  font-size: 18px;
  text-transform: uppercase;
  border-radius: 5px;
}
#titulo{
  background-color: var(--textColor2);
  padding: 10px;
}

.form-ouvidoria-container form {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* parte de upload*/  
.cadastroDocInterno {
  width: 100%;  

  height: fit-content;
  padding-bottom: 0px;
  border-radius: 15px 15px 15px 15px;
  cursor: default;
  color: var(--textColor);
}

.cadastroDocInterno > p:first-child{

  font-size: 25px;
}

.cadastroDocInterno p , .cadastroDocInterno  span{
  display: flex;
  position: relative;
  margin-left: 15px;
  margin-right: 10px;

  font-size: 20px;  
  line-height: 1.2;
  color: var(--textColor);
  text-align: left;
  top: 20px;
  left: 5px;
}

.cadastroDocInterno span {
  margin-top: 5px;
  margin-bottom: 0px;
}


.cadastroDocInterno  input[type=file]{
  height: 80px;
  width: 97%;
  top: 30px;
  position: relative;
  background-color: var(--textColor2);
  border-color: var(--mainColor2);
  border: 1px solid;
  margin-bottom: 25px;
  border-radius: 15px 15px 15px 15px;
  font-size: 16px;
  font-family: var(--fontA);
  padding-left: 10px;
}

.cadastroDoc {
  margin-left: -2%;
  margin-right: -2%;  
  margin-top: -10px;
  text-align: center;
  /*  pra não aparecer a virgula  */ 
  color: var(--mainColor2);  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: column;

  margin-top: 0px;
}

.cadastroDoc button{
  width: 97%;
  height: 50px;  

  cursor:pointer;
  font-size: 20px;
  font-weight: 100;
  background-color: var(--mainColor);
  color: var(--textColor2);

  margin: 20px 0px 20px 0px;
  border-radius: 15px 15px 15px 15px;
  border: var(--textColor) 0.8px;
  border-style:solid; 
}

/* Anexos já carregados*/  
.anexos {
  margin-top: 30px;
  color: var(--mainColor2);  
  height: fit-content;
}


.anexos > div{
  height: fit-content;
  overflow-y: hidden;  
  padding-bottom: 10px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: -2px;
  min-height: 40px;
  border: var(--textColor) solid 2px;
  overflow-x: auto;
}
.anexos > div > p {
  margin-left: 10px;
  margin-top: 0px;
  text-overflow: ellipsis;
  padding-bottom: 10px;
}

.anexos > div > a {
  cursor: pointer;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 0px;  
  
  position: relative;
  display: flex;
  float: left;

  width: 50%;
  height: 30px;  
  background-color: var(--mainColor);
  color: var(--textColor2);
  border-radius: 10px 10px 10px 10px;  
}
