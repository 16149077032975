
.noticiaEspecifico{
  /* 
  width: 98%;
  height: 120px;  
  margin-left: 1%; 
  margin-right: 1%; 
  background-color: var(--mainColor2);
  */  
  
  margin-top: 10%;
  font-size: 0;
  text-align: left;
  margin-bottom: 2%;
  
  /*   bloquear ficar página rolando */
  overflow: hidden;
  margin-bottom: 5%;
  
}




.noticiaTitulo{
  margin-left: 10vw;
  margin-right: 35vw;
  margin-bottom: -7vw;
  margin-top: -1vw;
  color: var(--mainColor);
  font-size: 40px;
  min-height: 90px;
}  


  /*  AJUSTE 2 NOTICIAS DIREITA */ 
.noticiaEspecifico > div > div:last-child{
  height:fit-content;
  width : 55vw;
  min-width: 380px;
  max-width: 440px;
  overflow: hidden;
  margin-top: -15px;
}


@media (min-width: 1620px) {
  .noticiaEspecifico > div > div:last-child{
    height:fit-content;
    width : 610px;
  }
}


.noticiaEspecificoInfo {

  margin-left: 10%; 
  margin-right: 10%; 
  margin-top: 1%;
  text-align: center;
  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;   
}

.noticiaEspecificoInfo > div:last-child{

  margin-left: 2%; 
  margin-right: 10%; 
  
        
}

/*limite do texto */ 
.textonoticiaEspecifico{
  font-size: 22px;
  text-align: justify;
  
  max-width : 1300px;
  min-width : 340px;
    
  line-height: 1.6;
  color: var(--mainColor);
  margin-top: 2rem;
}  

.noticiasInfoEspecifica{
  font-size: 22px;
  text-align: justify;
  
  max-width : 1000px;
  min-width : 340px;
    
  line-height: 1;
  color: var(--mainColor);
  
  margin-top: 12px;
  
}  

.noticiaEspecificoInfo > div:first-child{
    max-width: 65%;
}

.noticiaEspecificoInfo > div:last-child  button{
  
  width : 24.8vw;
  height: 21.7vw; 
  max-width : 400px;
  max-height: 350px; 
  min-width : 340px;
  min-height: 150px;

  margin: 16px;
    
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
}


/* classe novo botão ver mais (mais noticias)*/  
.noticiaEspecificoInfo > div:last-child  a:last-child button{
  
  width : 24.8vw;
  max-width : 400px;
  min-width : 280px;
  
  height: 1.7vw; 
  max-height: 350px; 
  min-height: 50px;

  margin: 16px;
    
  font-size: 22px;
  background-color: var(--mainColor);
  border-color: var(--mainColor);
  color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
      
}

.noticiaEspecificoDados {
  
  margin-top: 20px;
      
  background-color: var(--mainColor2);

  border-radius: 15px 15px 15px 15px;
  border: var(--mainColor2);
  border-style:solid; 
}

.noticiaEspecificoDados  button{
    
  width : 47%;
  height: 75px;

  margin: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
    
  font-size: 20px;
  background-color: var(--mainColor);
  border-color: var(--mainColor);
  color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
}


@media (max-width: 840px) {
  .noticiaEspecificoDados  button {  
    width : 95%;
  }
}



.noticiaEspecificoDados  button > span:first-child{
  font-size: 30px;
  position: relative;
  float: left;  
  top: -5px;
  left: 5px;
}

.noticiaEspecificoDados  button > span:last-child{
  position: relative;
  float: left;
  left: 8px;
  
  text-align: left;
  line-height: 1.2;
  height: 50px;
  width: 85%;
  overflow: hidden;
}


.noticiaEspecificoInfo div:last-child p {
  position: relative;

  left: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  text-align:left;

  white-space: break-spaces;

  height: 86px;
  width: 95%;

    /*corrigido resolução texto
  top: -14vh;
  */  
  top: -135px;  
  line-height: 1;
  color: var(--textColor2);
  text-shadow: 1px 1px 2px black;

  margin-left: 0vw;  
}

.noticiaEspecificoInfo div:last-child p:last-child{
  margin-top: 9px;
  margin-left: -10px;

  background-color: var(--mainColor);
  border-radius: 0px 15px 0px 15px;

  padding: 6px;
  height: 35px;
  width: 60%;
}

.noticiaEspecificoInfo button img{
  
  width : 100%;
  height: 100%; 
  object-fit: cover;
  
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  color: var(--textColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  filter: brightness(0.8);
}

.maps iframe{

  height: 270px;
  width: 100%;

  margin-top: 3%;
  text-align: center;
}


@media (max-width: 1290px) {
  .noticiaEspecificoInfo > div:first-child{
    max-width: 100%;
  }
  .noticiaEspecificoInfo {  
    flex-direction: column;
  }
  .noticiaEspecifico > div > div:last-child{
       height: auto;
    width : 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .noticiaEspecificoInfo > div:last-child  button{
    margin: 35px 16px 0px 16px;
  }

  .noticiaTitulo{
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 5vw;
    line-height: 1.2;
  }  

  .noticiaEspecificoInfo > div:last-child  a:last-child button{
    max-width: 100%;
    width: 70vw;
    left: 10vw;
    top: 0vw;   
  }
}


@media (max-width: 499px) {

  .noticiaTitulo{
    font-size: 32px;
    margin-left: 14vw;
    margin-right: 10vw;
    margin-top: 5vw;
    line-height: 1.2;
  }  

  .noticiasInfoEspecifica{
    font-size: 20px;  
  }
 
  .textonoticiaEspecifico {
    font-size: 20px;
    min-width : 300px;
  }

  .noticiaEspecificoInfo > div:last-child  button{
    min-width : 320px;
    margin-left: -3rem;
    margin-right: 0vw;
  }

  .noticiaEspecificoInfo div:last-child p {
    line-height: 1;
    font-size: 20px;
    height: 79px; 
    margin-top: 9px;
  }

  .noticiaEspecificoInfo div:last-child p:last-child{
    height: 30px;
  }

  .noticiaEspecificoInfo > div:last-child  a:last-child button{
    margin-left: -7vw;
  }
}

@media (max-width: 399px) {
  .noticiaEspecificoInfo div:last-child p {  
    margin-top: 16px;
    height: 56px; 
  }
  .noticiaEspecificoInfo div:last-child p:last-child{
    margin-top: 29px;
    height: 27px;
  }
  .noticiaEspecificoInfo > div:last-child  button{
    min-width : 300px;
    margin-left: -5rem;
  }
  .noticiaEspecificoInfo > div:last-child  a:last-child button{
    margin-left: -16vw;
  }
}

@media (max-width: 376px) {
  .noticiaEspecificoInfo div:last-child p {  
    margin-top: 16px;
    height: 56px; 
  }
  .noticiaEspecificoInfo div:last-child p:last-child{
    height: 27px;
  }
  .noticiaEspecificoInfo > div:last-child  button{
    min-width : 300px;
    margin-left: -5rem;
  }
  .noticiaEspecificoInfo > div:last-child  a:last-child button{
    margin-left: -16vw;
  }
}

.destaque > div:first-child{
  background-color: black;
}

.noticiaOutros > a{
  display: inline-flex;
}