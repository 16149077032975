
/*
background-color: var(--mainColor2);  
border: black;
border-style:solid; 
*/    

.modal_imagem{
    width: 80%;
    height: auto;
    
}

.container-img{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.conteudo-img h1{
    font-family: var(--fontA);
    font-size: 2.4rem;
    margin-bottom: 2rem;
}

.container-galeriaEspecifica{ 
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   
}

.container-imgEspecifico{   
    column-count: 2;
    width: 80rem;
    margin-bottom: 2rem;
    gap: 2rem;
    margin-top: 4rem;
   border-radius: 10px;
   filter: drop-shadow(0 0 0.75rem #12121225);
   background-color: rgba(254, 254, 254, 0.299);
   padding: 20px;
   border-radius:30px;    
}

.container-imgEspecifico img{
    border-radius:30px;  
    width: 80%;
    height: 40rem;  
    object-fit:cover;
}

.container-imgEspecifico p{
   
    margin: 1rem 0;
    text-align: justify;
    width: 90%;
}
.conteudo-img{
    padding-top: 2rem;
    color: var(--textColor);
}

.container-capa{
    width: 750px;
    height: auto;
}

.imagem-grande {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
  }
  
.imagens_relacionadas{
    margin-top: 2rem;
    display: flex;
}
.imagens_relacionadas img{
    cursor: pointer;
    border-radius: 10px;
    margin-left: 0rem;
    width: 18rem;
    height: 200px;

    background-color: rgba(255, 255, 255, 0.125);
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    filter: drop-shadow(0 0 0.75rem #12121253);
}

.imagens_relacionadas img:hover{
   filter: opacity(.8);
}

.btnGaleria{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
    cursor: pointer;
    margin-left: 0.7rem; 
    width: 10rem;
    height: 2.5rem;  
    padding: 10px;
    border: none;
    margin: 2rem 0;
    border-radius: 50px;
    transition: 0.3s ease;
    font-size: 1.3rem;

    color: var(--textColor2);
    background-color: var(--textColor);
}
.btnGaleria:hover{
    background-color: var(--textColor2);
    color: var(--textColor);
    border: 2px solid var(--textColor);
}

.btnGaleria svg {
    color: var(--textColor2);
    width: 1rem;
    height: 1rem;
}

/* Model */
.model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.9);
    transition: opacity 0.4s ease, visibility .4s ease, transform .5s ease;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open{
   
    width: 100%;
    height: 100vh;
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img{
    width: auto ;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
         
}
.model.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2.5rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0,0,0,0.4);
    color: #ffffff;
    cursor: pointer;
}

.mains_imagens{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;       
}

@media(width < 321px){
  
    .container-galeriaEspecifica{
        width: 100%;
        overflow-x: hidden;      
    }
    .conteudo-img p{
        width: auto;
    }

    .container-imgEspecifico{  
        width: 90%;
        display: flex; 
        flex-direction: column; 
        
        /* style */
        border-radius: 10px;
        filter: drop-shadow(0 0 0.75rem #12121225);
        background-color: rgba(254, 254, 254, 0.299);
        padding: 20px;
        border-radius:30px;    
    }

    .container-imgEspecifico h1{
        font-size: 1.5rem;
        text-align: center;
    }

    .container-imgEspecifico img {
        width: 16rem;
        height: auto;
    }
    .imagens_relacionadas{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
    }

    .mains_imagens{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }


    /* Modal */

    .modal_imagem{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 100%;
        position: fixed;
        overflow: hidden;
    }
    .model{
        width: 80%;
        height: 100vh;
        
    }
    
    .model.open{
        width: 100%;
        height: 100%;
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }
    
    .model img{
      width: 200px;
             
    }
    .model.open svg{
        position: fixed;
        top: 10px;
        right: -1px;
        width: 2rem;
        height: 2rem;
        padding: 5px;
        background-color: rgba(0,0,0,0.4);
        color: #ffffff;
        cursor: pointer;
    }
   

}

@media( width > 320px) and (width < 376px){

    .container-galeriaEspecifica{
        width: 90%;
        overflow-x: hidden;
    }
    .conteudo-img p{
        width: auto;
    }
    .container-capa{
        width: 100%;
        height: auto;
    }

    .container-imgEspecifico{  
        width: 90%;
        display: flex; 
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        
        /* style */
        border-radius: 10px;
        filter: drop-shadow(0 0 0.75rem #12121225);
        background-color: rgba(254, 254, 254, 0.299);
        padding: 20px;
        border-radius:30px;    
    }

    .container-imgEspecifico h1{
        font-size: 1.5rem;
        text-align: center;
    }

    .container-imgEspecifico img {
        width: 17rem;
        margin-left: 0rem;
        height: auto;
    }
    .imagens_relacionadas{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 3rem;
    }

    .mains_imagens{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }



    .modal_imagem{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 80%;
        position: fixed;
        overflow: hidden;
    }
    .model{
        width: 100%;
        height: 100vh;
        
    }
    
    .model.open{
        width: 100%;
        height: 100%;
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }
    
    .model img{
      width: 200px;
             
    }
    .model.open svg{
        position: fixed;
        top: 10px;
        right: 1px;
        width: 2rem;
        height: 2rem;
        padding: 5px;
        background-color: rgba(0,0,0,0.4);
        color: #ffffff;
        cursor: pointer;
    }

}

@media( width > 375px) and (width < 426px){
    .container-galeriaEspecifica{
        width: 100%;
        overflow-x: hidden;      
    }

    .conteudo-img p{
        width: auto;
    }
    .container-capa{
        width: 100%;
        height: auto;
    }

    .container-imgEspecifico{  
        width: 90%;
        display: flex; 
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        
        /* style */
        border-radius: 10px;
        filter: drop-shadow(0 0 0.75rem #12121225);
        background-color: rgba(254, 254, 254, 0.299);
        padding: 20px;
        border-radius:30px;    
    }

    .container-imgEspecifico h1{
        font-size: 1.5rem;
        text-align: center;
    }

    .container-imgEspecifico img {
        width: 20rem;
        margin-left: 0rem;
        height: auto;
    }
    .imagens_relacionadas{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 8rem;
    }
    

    .mains_imagens{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }


    .modal_imagem{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 80%;
        position: fixed;
        overflow: hidden;
    }
    .model{
        width: 100%;
        height: 100vh;
        
    }
    
    .model.open{
        width: 100%;
        height: 100%;
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }
    
    .model img{
      width: 200px;
             
    }
    .model.open svg{
        position: fixed;
        top: 10px;
        right: 1px;
        width: 2rem;
        height: 2rem;
        padding: 5px;
        background-color: rgba(0,0,0,0.4);
        color: #ffffff;
        cursor: pointer;
    }
}

@media( width > 425px) and (width < 769px){
    .container-galeriaEspecifica{
        width: 100%;
        overflow-x: hidden; 
            
    }

    .conteudo-img p{
        width: auto;
    }
    .container-capa{
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
    }

    .container-imgEspecifico{  
        width: 90%;
        display: flex; 
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        
        /* style */
        border-radius: 10px;
        filter: drop-shadow(0 0 0.75rem #12121225);
        background-color: rgba(254, 254, 254, 0.299);
        padding: 20px;
        border-radius:30px;    
    }

    .container-imgEspecifico h1{
        font-size: 1.5rem;
        text-align: center;
    }

    .container-imgEspecifico img {
        
        width: 20rem;
        margin-left: 0.7rem;
        height: auto;
    }
    .imagens_relacionadas{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        
        justify-content: center;
    }

    .mains_imagens{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }

    .model.open svg{
        position: absolute;
       right: 10px;
    }

    .model.open img {
        width: 100%;
        height: auto;
    }
    

}

@media( width > 768px) and (width < 1260px){
    .container-galeriaEspecifica{
        width: 100%;
        overflow-x: hidden;     
       
    }

    .conteudo-img p{
        width: auto;
    }
    .container-capa{
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
    }

    .container-imgEspecifico{  
        width: 90%;
        display: flex; 
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        
        /* style */
        border-radius: 10px;
        filter: drop-shadow(0 0 0.75rem #12121225);
        background-color: rgba(254, 254, 254, 0.299);
        padding: 20px;
        border-radius:30px;    
    }

    .container-imgEspecifico h1{
        font-size: 1.5rem;
        text-align: center;
    }

    .container-imgEspecifico img {
        
        width: 25rem;
        margin-left: 0.7rem;
        height: auto;
    }
    .imagens_relacionadas{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-left: 5rem;
    }

    .mains_imagens{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;       
    }

}
