.galeriaPessoas   {    
  margin-bottom: 1rem;
}

.utitulo{        
  margin-left: 8.7%;
}  

@media (max-width: 664px) {
  .utitulo{        
    margin-left: 4rem;
  }  
}

@media (max-width: 499px) {
  .utitulo{   
    margin-top: -20px;     
    margin-left: 1.6rem;
  }  
}

.pessoas   {

  margin-left: 8%;
  margin-right: 8%;  
  margin-top: 2%;
  text-align: center;
  /*  pra não aparecer a virgula  */ 
  color: var(--mainColor2);  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;
  
  
}

.pessoas button{

  width: 250px;  
  min-width: 250px;

  /*
  max-width : 320px;
  max-height: 280px;   
  min-height: 150px;
  */      

  margin: 10px;
  background-color: var(--mainColor);
  border-radius: 15px 15px 15px 15px;
  
}

.pessoas button:first-child{
  cursor: pointer;
}

.pessoas    p {
  padding-bottom: 10px;
  top: 0.5vh;
  bottom: 1vh;

  font-size: 22px;
  
  line-height: 1.2;
  color: var(--textColor2);
  text-shadow: 1px 1px 2px black;
}

.pessoas   button img{
  position: relative;
  width : 100%;

  object-fit: cover;
      
  border-radius: 15px 15px 15px 15px;
  filter: brightness(0.8);
  filter: grayscale(1);
}

.pessoas button:first-child img{
  filter: grayscale(0);
}

.pessoas   button img:hover{
  filter: grayscale(0);
}
