.ouvidoria-container{
    /*
    background-color: var(--mainColor2);  
    border: black;
    border-style:solid; 
    */   
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-left: 8%;
    margin-right: 8%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.scrolOuvidoria{
    overflow: hidden;
}

.scrolOuvidoria h1{
    font-weight: normal;
}

.ouvidoria-title{
    margin-left: 2rem;
}

.ouvidoria-text{
    display: flex;
    justify-content: space-between;    
}
.ouvidoria-text .space{
    margin-bottom: 2rem;
}
.ouvidoria-text p{
    width: auto;
    font-family: var(--fontA);
    font-size: 1.2rem;
    font-weight: normal;
    color: var(--textColor);
    text-align: justify;  
}

.ouvidoria-info{
  width: 65%;
}

.ouvidoria-info:last-child{
    width: 30%;
}

@media (max-width: 1100px) {
    .ouvidoria-text{
        display: grid;
    }
    .ouvidoria-info{
        width: 100%;
    }    
    .ouvidoria-info:last-child{
        width: 100%;
        text-align: center; 
    }
    .ouvidoria-info:last-child p{
        text-align: center; 
    }
}

.ouvidoria-info h2{
    color: var(--textColor);
    font-size: 30px;
    font-weight:bold;
}

.ouvidoria-info h2{
    line-height: 2;
}

.ouvidoria-info button {
    background: none;
    border: none;
    margin-top: 1rem;
    background-color: var(--mainColor4);
    color: var(--textColor2);
    padding: 10px;
    border-radius: 10px;
    width: 16rem;
    height: 3rem;
    font-size: 1.2rem;
    margin-top: 4rem;
    cursor: pointer;
}

.ouvidoria-grafico-barra{
    margin-left: 8%;
    margin-right: 8%;
    overflow-x: auto;
    overflow-y: hidden;
    height: 630px;
    
    background-color: var(--mainColor2);
}

.ouvidoria-grafico-text{
    margin-left: 8%;
    margin-right: 8%;

    justify-content:space-around;
    margin-top: 2rem;
    margin-bottom: 2rem;
    
    display:flex; 
    height: fit-content;
}

@media (max-width: 1535px) {
    .ouvidoria-grafico-text{
        justify-content:inherit;
        overflow: auto;
        display:flex; 
    }
}

.ouvidoria-grafico-text h2{
    background: none;
    border: none;
    
    margin-top: 1rem;
    background-color: var(--mainColor4);
    color: var(--textColor2);
    padding: 10px;
    border-radius: 10px;
    font-family: var(--fontA);
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 30px;
    text-transform: uppercase;
    max-width: 40%;
    height: 3.2rem; 
}
.ouvidoria-grafico-text p{
    width: 40rem;
    text-align: justify;
    margin-bottom: 1.5rem;
    font-family: var(--fontA);
    font-style: normal;
    font-weight: normal;
    margin-top: 2.5rem;
}

.ouvidoria-manifestacao-grafico{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-left: 8%;
    margin-right: 8%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: var(--mainColor2);
}

#title-ouvidoria-info{
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;   
    gap: 10px;
    height: fit-content;
}

#title-ouvidoria-info h2{
    font-size: 1.4rem;
    text-align: center;     
        
    max-width: 24rem;
    margin-left: 10px;
    margin-right: 10px;
}

#title-ouvidoria-info p{
    min-width: auto;
    margin-top: 1.5rem;       
    text-align: justify; 
    
    max-width: 24rem;
    margin-left: 10px;
    margin-right: 10px;
}














.ouvidoria-manifestacao-text{
    margin-left: 8%;
    margin-right: 8%;

    justify-content:space-around;
    margin-top: 2rem;
    margin-bottom: 2rem;
    
    display:flex; 
    height: fit-content;
}

@media (max-width: 980px) {
    .ouvidoria-manifestacao-text{
        justify-content:inherit;
        overflow: auto;
        display:flex; 
    }
}

.ouvidoria-manifestacao-text h2{
    background: none;
    border: none;
    
    margin-top: 1rem;
    background-color: var(--mainColor4);
    color: var(--textColor2);
    padding: 10px;
    border-radius: 10px;
    font-family: var(--fontA);
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 30px;
    text-transform: uppercase;
    max-width: 40%;
    height: 3.2rem; 
}
.ouvidoria-manifestacao-text p{
    width: 40rem;
    text-align: justify;
    margin-bottom: 1.5rem;
    font-family: var(--fontA);
    font-style: normal;
    font-weight: normal;
    margin-top: 2.5rem;
}


#title-manifestacao-info{
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;   
    gap: 10px;
    height: fit-content;
}

#title-manifestacao-info h2{
    font-size: 1.4rem;
    text-align: center;     
        
    max-width: 24rem;
    margin-left: 10px;
    margin-right: 10px;
}

#title-manifestacao-info p{
    min-width: auto;
    margin-top: 1.5rem;       
    text-align: justify; 
    
    max-width: 24rem;
    margin-left: 10px;
    margin-right: 10px;
}

@media (max-width: 499px) {
    #title-manifestacao-info h2{
        max-width: 20rem;
    }    
    #title-manifestacao-info p{        
        max-width: 20rem;
    }
}

@media (max-width: 376px) {
    #title-manifestacao-info h2{
        max-width: 19rem;
    }    
    #title-manifestacao-info p{        
        max-width: 19rem;
    }
}

@media (max-width: 360px) {
    #title-manifestacao-info h2{
        max-width: 18rem;
    }    
    #title-manifestacao-info p{        
        max-width: 18rem;
    }
}

@media (max-width: 499px) {
    #title-ouvidoria-info h2{
        max-width: 20rem;
    }    
    #title-ouvidoria-info p{        
        max-width: 20rem;
    }
}

@media (max-width: 376px) {
    #title-ouvidoria-info h2{
        max-width: 19rem;
    }    
    #title-ouvidoria-info p{        
        max-width: 19rem;
    }
}

@media (max-width: 360px) {
    #title-ouvidoria-info h2{
        max-width: 18rem;
    }    
    #title-ouvidoria-info p{        
        max-width: 18rem;
    }
}