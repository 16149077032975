@media (width < 1126px) and (height < 690px) {
  .concursoEsp     {    
    margin-top: 18vw;
  }
}

@media (max-width: 499px) {
  .licitacaoEspCard p {
    font-size: 18px;  
  }
 
  .concursoEsp     {    
    margin-top: -5vw;
  }
  .licitacaoEspCard button{
    font-size: 16px;
    margin-right: 10px;
    margin-top: -5px;
  }
  .licitacaoEspCard:nth-child(2) button{
    margin-top: 10px;
  }
}

@media (min-width: 500px) {
  .licitacaoEspCard button{
    font-size: 20px;
  }
}

.concursoEsp     {    
  margin-bottom: 4vw;
}

.InicialNome{     
  font-size: 32px;   
  margin-left: 8%;
  color: var(--mainColor);
}  

.InicialNome p:last-child{
  font-size: 18px;
}  

/* geral*/ 
.licitacaoEspCard {

  margin-left: 8%;
  margin-right: 8%;  
  margin-top: 2%;
  text-align: left;
  gap: 20px;

  color: var(--textColor2);  
  padding-bottom: 20px;

  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: column;

  background-color: var(--mainColor2);
  border-radius: 15px 15px 15px 15px;  
  box-shadow: var(--mainColor) 0px 0px 0px 0.5px;

  
}

.licitacaoEspCard > div > div {

  margin-left: 8%;
  margin-right: 8%;  
  margin-top: 2%;
  text-align: left;
  gap: 20px;

  color: var(--textColor2);  
  padding-bottom: 20px;

  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;

  background-color: var(--mainColor2);
  border-radius: 15px 15px 15px 15px;  
  box-shadow: var(--mainColor) 0px 0px 0px 0.5px;

  
}

.licitacaoEspCard p {
  position: relative;
  margin-left: 5vh;
  margin-right: 5vh;
  margin-bottom: 5px;

  line-height: 1.2;
  color: var(--textColor);
  
}

.licitacaoEspCard button {
  cursor: pointer;
  position: relative;
  float: right;
  
  right: -20px;
  top: 6px;
  width: 160px;
  height: 35px;
  
  line-height: 1.2;
  background-color: var(--mainColor);
  color: var(--textColor2);
  border-radius: 10px 10px 10px 10px;  
  
}
.licitacaoEspCard > div {
  color: var(--mainColor2);  
}

/* linha*/ 
.linha p{
  border-bottom: var(--textColor) solid 2px;
}
.linha p:nth-child(1){
  border-style:none;
  border:transparent;
}
