
.modalButton{
    display: flex;
    position: relative;
    top: 10px;
}

.modalButton button {
    background: none;
    outline: none;
    border: white 1.5px;
    border-style:solid ; 

    background-color: #004348;
    color: white;
    font-size: 22px;

    border-radius: 15px;
    padding: 12px 55px;    
    cursor: pointer;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    flex-direction: column;   
}

.modal > iframe{    
    width: 200vw;
    height: 100vw;
    max-width: 55vw;
    max-height: 30vw;
    justify-content: center;
    background-color: #004348;
    color: white;
}   


@media (max-width: 1000px) {
    .modal > iframe{
        min-width: 90vw;
        min-height: 50vw;
    }
}