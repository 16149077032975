.pagina {
  /* 
  width: 98%;
  height: 120px;  
  margin-left: 1%; 
  margin-right: 1%; 
  background-color: var(--mainColor2);
  */  
  
  margin-top: 4%;
  font-size: 0;
  text-align: left;
  margin-bottom: 2%;
  
  /*   bloquear ficar página rolando */
  overflow: hidden;

}

  /*  card */ 
.infos{
  height:85vh; 
  width : 500px;
  overflow: hidden;
  margin-top: 3.2vw;

  display:flex;
  flex-direction: column;
  min-height: 750px; 
}


.paginaInfo {

  margin-left: 8%; 
  margin-right: 10%; 
  margin-top: 1%;
  text-align: center;
  
  /*  
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;
}

.sobre{
  font-size: 22px;
  text-align: justify;
  
  max-width : 950px;
  min-width : 340px;
      
  margin-left: 10px;
  line-height: 1.6;
  color: var(--mainColor); 
}  

.sobre:first-child{
  font-size: 32px;
}

.buttonA{

  height: 50rem;

  max-width : 400px;
  max-height: 310px; 
  min-width : 340px;
  min-height: 150px;

  background-color: var(--mainColor);    
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 0px 0px;
  cursor: default;
  
  
}

.buttonB{

  max-width : 400px;  
  min-width : 340px;

  max-height: 70px; 
  
  background-color: var(--mainColor);    
  border-color: var(--textColor2);
  border-style: none;
  cursor: text;

  display: flex;  
}

.buttonB:last-child{
  border-radius: 0px 0px 15px 15px;
  height: fit-content;
  max-height: 200px; 
  text-align: left;
}


.buttonB:last-child span{
  margin-top: 5px;
}

.buttonC{

  max-width : 400px;  
  min-width : 340px;
  
  background-color: var(--mainColor);    
  border-color: var(--textColor2);
  border-style: none;
  cursor: text;

  display: flex;  

  height: 82px; 
  max-height: 200px; 
  text-align: left;
}

.buttonC span{
  margin-top: 10px;
}

.buttonD{

  max-width : 400px;  
  min-width : 340px;
  
  background-color: var(--mainColor);    
  border-color: var(--textColor2);
  border-style: none;
  cursor: text;

  display: flex;  

  height: 150px; 
  max-height: 200px; 
  text-align: left;
}

.buttonD img{

  max-width: 120px; 
  height: 135px;
  margin-left: 14px;
  top: 18px;

  position: relative;
  object-fit: cover;
  
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  color: var(--textColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
   /*  AJUSTE MANUAL DAS FOTOS  */ 
  object-position: 0 20%;
}

.buttonD span{
  margin-left: 15px; 
  line-height: 1.8;
  margin-top: 0px;
}

.infos span{
  font-size: 20px;
  margin-top: 15px;
  margin-left: 5px; 
  color: var(--textColor2);
}

.icones{
	background: transparent;
	border: transparent;
	outline: transparent;
	color: var(--mainColor2);
	font-size: 2.4rem;

  margin-left: 10px;
  margin-top: 10px;
  cursor: default;
}

.Linkicone{
	background: transparent;
	border: transparent;
	outline: transparent;
	color: var(--mainColor2);
	font-size: 1.40rem;

  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.butSecr{
  top: 30px;
  width: fit-content;
  position: relative;
}

.paginaInfo img{
  
  width : 90%;
  max-height: 180px;
  margin-top: -4%;
  object-fit: cover;
  
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  color: var(--textColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
   /*  AJUSTE MANUAL DAS FOTOS  */ 
  object-position: 0 20%;
}

.infos  p {
  position: relative;

  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  text-align:center;

  white-space: break-spaces;

  width: 95%;
  height: 40px;
  left: 10px;
  margin-left: 0vw;  
  top: 1.5vh;

  line-height: 1;
  color: var(--textColor2);
  text-shadow: 1px 1px 2px black;
}

@media (max-width: 1290px) {
  .paginaInfo {  
    flex-direction: column;
  }
  .infos{
    height: 50rem; 
    max-width: 380px;
    margin: auto;
  }
}


@media (max-width: 499px) {
  .sobre {
    font-size: 20px;
    min-width : 300px;
  }
  .infos  p {
    font-size: 20px;
    height: 38px; 
  }
  .infos{
    margin-left: -15px;
  }
}

@media (max-width: 399px) {
  .infos{
    margin-left: -25px;
  }
}

@media (max-width: 376px) {
  .infos{
    width: 360px;
  }
  .sobre {
    margin-left: -1px;
  }
}

@media (max-width: 361px) {
  .infos{
    width: 354px;
  }
  .sobre {
    min-width : 295px;
  }
}

.noticiaSecretarias  {

  margin-left: 10%; 
  margin-right: 10%; 
  margin-top: -2%;
  text-align: center;
  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;
}

.noticiaSecretarias  button{
  
  width : 24.8vw;
  height: 21.7vw; 
  max-width : 320px;
  max-height: 280px; 
  min-width : 340px;
  min-height: 150px;

  margin: 16px;
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;

  line-height: 1;
  cursor: pointer;
}

.noticiaSecretarias   p {
  position: relative;

  left: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  text-align:justify;

  white-space: break-spaces;

  height: 86px;
  width: 95%;

  /*   top: -14vh;*/  
  top: -135px;
  line-height: 1;
  color: var(--textColor2);
  text-shadow: 1px 1px 2px black;
}

.noticiaSecretarias  button img{
  
  width : 100%;
  height: 100%; 
  object-fit: cover;
  
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  color: var(--textColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  filter: brightness(0.8);
}

/* Indentificação das secretarias*/  
.noticiaSecretarias div button  p:last-child {
  margin-top: 10px;
  margin-left: -10px;

  background-color: var(--mainColor);
  border-radius: 0px 15px 0px 15px;

  padding: 6px;
  height: 35px;
  width: 52%;
}

.menuSecretaria  {
  margin-top: 10vh;
  width: 100%;
  /* 
  width: 98%;
  margin-left: 1%; 
  margin-right: 1%; 
  */  
  background-color: var(--mainColor2);
  /* reduzir espçamento button */  
  font-size: 0;
  text-align: center;
  
  /* espaço no final menu */ 
  margin-bottom: 4%;
  padding-bottom: 2%;  
}

.menuSecretaria  li button {
  top: -50px;
  position: relative;
  width: 120px;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  cursor: pointer;
}

.menuSecretaria  li button:hover{
  border-radius: 15px 15px 15px 0px;

  background-color: var(--mainColor);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.menuSecretaria  li button:first-child {
  width: 120px;
}

.menuSecretaria  li button:last-child {
  width: 180px;
  border-radius: 15px 15px 15px 0px;
}

.menuSecretaria  li button:last-child:hover{
  width: 180px;
  border-radius: 15px 15px 15px 0px;
}


@media (max-width: 700px) {
  .divSobre{
    max-height: 300px; 
    overflow-y: auto;    
    overflow-x: hidden;   
    margin-bottom: 30px;
  }  
}

@media (max-width: 499px) {

  .noticiaSecretarias  p {  
    margin-top: 42px;
    height: 43px;
  }
  .noticiaSecretarias{  
    margin-left: 0%; 
    margin-right: 0%; 
  }
}

