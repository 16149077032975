@font-face {
  font-family: "Reem Kufi Regular";
  src: url("../Geral/Reem\ Kufi\ Regular.ttf");
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: var(--fontA);
}


/* 

:root {
	--mainColor: #004348;
	--mainColor2: #D9D9D9;  
  --mainColor3: #b6a6a6;
  --mainColor4: #24977B;

  --textColor: #004348;
	--textColor2: white;

  --fontA: 'Reem Kufi Regular';  
}

*/ 

body {
  font-size: 22px;
  font-weight: 200;
  overflow-x: hidden;
}

a{
  color: inherit;
}

.tituloPagina {
  font-size: 22px;
        
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 20px;
  font-size: 32px;

  color: var(--textColor2);
  background-color: var(--mainColor);
  max-width: fit-content;
  padding: 6px 30px;
  border-radius: 30px 10px;
  
}

/* react alert */ 
.MuiAlert-root{
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 10px;
}
.MuiAlert-message{
  color: var(--textColor4);
  font-size: 20px;  
  line-height: 1.2;
  margin-top: 3px;
}
.MuiAlert-icon{
  font-size: 1.6rem;
  color: var(--textColor4);
}

/* tela de espera*/ 
.MuiBackdrop-root p, .MuiBackdrop-root span{
  text-align: center;
  margin-top: -60vh;
  margin-right: 15px;
  color: white;
  font-size: 35px;
}


/* COMPONENTE DA GALERIA*/ 

.containerGaleria{  
  display: flex;
  justify-content: center;
  margin-top: 1rem; 
  margin-bottom: 2rem; 
}
.containerGaleria img{
  width: 100%;
  filter: brightness(100px);
  transition: 0.7s all ;  
  border-radius: 15px;
  margin-bottom: 1rem;
  filter: brightness(70%);
}
.containerGaleria img:hover{
  cursor: pointer;
  filter: brightness(90%);
}
.containerGaleriaImgs{
  margin-left: 8%;
  margin-right: 8%;  
  
  column-count: 4;
  column-gap: 2rem;    
}
.containerGaleriaTextImg{
  break-inside: avoid-column;
  background-color: rgba(255, 255, 255, 0.125);
  padding: 10px;
  border-radius: 10px;
  filter: drop-shadow(0 0 0.75rem #12121225);
}
.containerGaleriaTextImg p{    
  position: relative;
  top: -15px;
  line-height: 1;
  font-size: 24px;
  font-family: var(--fontA);
  color: var(--textColor);
 /* padding-left: 1rem;
   border-bottom: #57C9AE 3px solid; */
}

.containerGaleriaTextImg > p:first-child{    
  position: relative;
  
  top: 40px;
  height: 40px;
  width: fit-content;

  z-index: 1;  

  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.4rem;
  text-align:justify;
  white-space: break-spaces;  
  line-height: 1;
  color: var(--textColor2);
  text-shadow: 1px 1px 2px black;

  background-color: var(--mainColor);
  border-radius: 15px 0px 15px 0px;
  padding: 10px;
}

@media (width < 416px) {
  .containerGaleriaTextImg p{ 
      line-height: 1;
      font-size: 20px;
  }
  .containerGaleriaImgs img{
      width: 10rem;
  }    
  .containerGaleriaImgs{
      columns: 2;
      column-gap: 0rem;      
  }
}
@media (width > 416px) and (width < 532px) {
  .containerGaleriaImgs img{
      width: 12rem;
  }    
  .containerGaleriaImgs{
      columns: 2;
      column-gap: 1rem;      
  }
}
@media (width > 531px) and (width < 808px) {
  .containerGaleriaImgs img{
      width: 15rem;
  }    
  .containerGaleriaImgs{
      columns: 2;
      column-gap: 1rem;      
  }
}
@media (width > 808px) and (width < 1025px) {
  .containerGaleria{
      width: 100%;
  }
  .containerGaleriaImgs img{
    
      width: 20rem;
  }    
  .containerGaleriaImgs{
      columns: 2;
      column-gap: 2rem;      
  }
}
@media (width > 1024px) and (width < 1366px) {

  .containerGaleria{
      width: 100%;
  }
  .containerGaleriaImgs img{
      width: 100%;
  }    
  .containerGaleriaImgs{  
      columns: 3;
      column-gap: 0rem;      
  }
}
@media (width > 1366px) and (width < 1441px) {
  .containerGaleria{
      width: 100%;
  }
  .containerGaleriaImgs img{
      width:  100%;
  }    
  .containerGaleriaImgs{  
      columns: 3;
      column-gap: 0rem;      
  }
}

/* COMPONENTE DA GALERIA*/ 

/*  Comp GALERIA DIREITA (Noticias) */ 

.EspContainerGaleria{
  margin-top: 0rem; 
  overflow-y: auto;
  overflow-x: hidden;

  
  height: auto; 

  /*  
  noticias relacionadas ficar menor
   width : 30vw;
   */ 
  width : 30vw;
  display: block;

  text-align: left;
  
}

.EspContainerGaleriaImgs{
  margin-left: 8%;
  margin-right: 8%;  
  
  column-count: 1;
  column-gap: 2rem;    
}

.EspContainerGaleriaTextImg{
  margin-top: -40px;
}

.EspContainerGaleriaTextImg p{    
  position: relative;
}

@media (max-width: 1290px) {

  .EspContainerGaleria{
    margin-top: 0rem;  
    height: fit-content;
    width : 100%;    
    display: block ;  
    overflow-y: auto;
    overflow-x: auto;
  }

  .EspContainerGaleriaImgs{    
    column-count: 3;   
  }
  .EspContainerGaleriaTextImg{
    margin-top: 0px;
  }  
}

@media (max-width: 1290px) {

  .EspContainerGaleria{
    margin-top: 0rem;  
    height: fit-content;
    width : 100%;    
    display: block ;  
    overflow-y: auto;
    overflow-x: auto;
  }

  .EspContainerGaleriaImgs{    
    column-count: 3;   
  }
  .EspContainerGaleriaTextImg{
    margin-top: 0px;
  }  
}

@media (max-width: 1025px) {
  .EspContainerGaleriaImgs{    
    column-count: 2;   
  }
}

@media (max-width: 935px) {
  .EspContainerGaleriaImgs{    
    column-count: 1;   
  }
  .EspContainerGaleria{
    text-align: center;
    width: 100%;
  }

  .EspContainerGaleriaTextImg > p:first-child{    
    margin-left: 14.5vw;
  }

  .EspContainerGaleriaTextImg > p:last-child{    
    width: 45vw;
    margin-left: 10vw;
  }
}

@media (max-width: 499px) {
  .EspContainerGaleriaTextImg > p:first-child{    
    margin-left: 11vw;
  }

}

/*  Comp GALERIA DIREITA (Noticias) */ 

/* fundo das galerias ficar preto */ 
.destaque > div:first-child{
  background-color: black;
}

/* filtros obras, noticias, pontos turistico */ 
.selecionada{
  width: 310px;
  min-width: 310px;

  height: 50px; 
  left: 10vw;
  right: 10vw;
  top: 8vw;   
     

  position: relative;
  text-align: center;   
  font-size: 22px;
  
  background-color: var(--mainColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
}

@media (max-width: 1300px) {
  .selecionada{
    top:7vw; 
  }
}
@media (max-width: 950px) {
  .selecionada{
    top:7vw; 
    font-size: 19px;
  }
}
