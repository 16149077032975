
header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 50px;
	background-color: var(--mainColor);	
	color: var(--textColor2);
 	box-shadow: -10px 5px 5px rgb(150, 148, 148); 
		
}


.admin{
	align-items: center;
	display: flex;
	float: right;
	right: 1px;
	position: absolute;
  }

nav a {
	margin: 0 1rem;
	color: var(--textColor2);
	text-decoration: none;
}

nav a:hover {
	color: var(--textColor);
	background-color: white;
}

header .nav-btn {
	float: left;
	padding: 10px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--mainColor2);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
	
}

header > button{
	 /* 
   	margin-right: 100%;	
	*/  
	background-color: transparent;
	margin-left: -88%;	
}


header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1125px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
		width: 100%;
		display: flex;
		justify-content: flex-end;

	}

	header nav {
		position: fixed;
		top: 0;
		left: 0;
		height: 35rem;
		width: 25%;
		min-width: 12rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.8rem;
		background-color: var(--mainColor);
		transition: 1s;
		transform: translateY(-120vh);
    	z-index: 5;
		
	}

	header .responsive_nav {
		transform: none;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 0rem;
		right: 0rem;
	}


	 /* já pega do geral
   	nav a {
		font-size: 22px;
	}
	*/ 	

  .admin{
    padding-top: 1rem;
    width: 100%;
    border-top: #eee solid 2px;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
	position: relative;
	
  }
}

/* ANTIGO INICIO*/ 	

.pesquisa {
	float: right;
	height: 50px;
	width: 30%;
	max-width: 360px;
	min-width: 200px;
	margin-top: 2px;
	/* 
	 border: 2px;
	border-color: white;
	border-style: solid;	
	*/  
	box-shadow: -24px 4px 4px rgb(150, 148, 148);
	
}
  
.pesquisa input{
	color: black;
	border: white;
	background-color: #F6DCC9;
	opacity: 80%;
	height: 50px;
	margin-left: -25px;
	width: 100%;
	text-align: center;   
	font-size: medium;
}
.pesquisa input:focus {
	outline: none !important;
	border: 2px;
	border-color: white;
	border-style: solid;
}
  
@media (max-width: 500px) {
	.pesquisa input{
		margin-left: -12px;
	}
	.pesquisa{
		box-shadow: -12px 4px 4px rgb(150, 148, 148);
	}
}
  
.lupa{
width: 35px;
height: 35px; 
position: relative;
top: -45px;
margin-right: 20%;
float: right;
opacity: 50%;
}

::placeholder {
color: black;
opacity: 80%;
font-size: larger;
font-weight: 100;
}
    
.logo1 {
float: right;
width: 350px;
height: 180px;
right: 10px;
position: absolute;
top: 390px;
background-color: var(--mainColor2);
border-radius: 20px 20px 20px 0px;
filter:drop-shadow(2px 2px 2px gray);

}

.logo1 img{
height: 90%;
width: 100%;
object-fit: cover;
}


  
@media (max-width:  1125px) {
	.logo1 {
		top: 35vw;
		width: 290px;
		height: 160px;
	}
}

@media (max-width: 968px) {

	.logo1 {
		top: 40vw;
		width: 270px;
		height: 140px;
	}
}

@media (max-width: 900px) {

	.logo1 {
		top: 42vw;
	}
}


@media (max-width: 830px) {
	.logo1 {
		top: 45vw;
		width: 240px;
		height: 120px;
	}
}



@media (max-width: 820px) {
	.logo1 {
		top: 41vw;
}  
}


@media (max-width: 740px) {
	.logo1 {
		top: 42vw;
	}
}

@media (max-width: 540px) {
	.logo1 {
		width: 200px;
		height: 80px;
		top: 50vw;
	}
}

@media (max-width: 400px) {
	.logo1 {
		width: 200px;
		height: 80px;
		top: 50vw;
	}
}


.banner {
	width: 100%;
	position: relative;
	top: -45px;
	z-index: -1;
}
   
.banner img{
	width: 100%;
	max-height: 480px;
	object-fit: cover;
}

@media (max-width: 1300px) {
	.banner {
		overflow: hidden;	
		max-height: 30rem;
		min-height: 30rem;
		background-color: var(--mainColor);
	}	
	.banner img{
		height: 100%;
		position: relative;	
	}
}

@media (max-width: 840px) {
	.banner {
		max-height: 55vw;
		min-height: 28rem;
	}
}

@media (max-width: 800px) {
	.banner {
		max-height: 25rem;
		min-height: 25rem;
	}
}


@media (max-width: 700px) {
	.banner {
		max-height: 23rem;
		min-height: 23rem;
	}
}


@media (max-width: 620px) {
	.banner {
		max-height: 21rem;
		min-height: 21rem;
	}
}

@media (max-width: 580px) {
	.banner {
		max-height: 20rem;
		min-height: 20rem;
	}
}

@media (max-width: 530px) {
	.banner {
		max-height: 18rem;
		min-height: 18rem;
	}
}

@media (max-width: 499px) {
	.banner {
		max-height: 14rem;
		min-height: 14rem;
	}
}

.botao_topo{
	display: flex;
	justify-content: center;
	align-items: center;
  
	width: 60px;
	height: 60px;
	background-color: var(--mainColor4);
	border-radius: 50%;
	cursor: pointer;
	position: fixed;
	right: 1rem;
	bottom: 8%;
	z-index: 9  ;
  
  }
  .botao_topo svg{
	font-size: 30px;
	color: white;
  }